import $api from '@http/$api'
import { IDevice } from '@interfaces/IDevices'

const getDevices = async () => {
    // console.log('ClinetAPI GET_USPD')
    const response = await $api.get<IDevice[]>('/device/list')
    return response.data
}

const devicesApi = {
    getDevices
}

export default devicesApi
