import React, { useState, useEffect } from 'react'
// interfaces
import { IAccessMonitoringUserList, IAccessMonitoring, IAccessMonitoringUser} from '@interfaces/IAccessMonitoring'
// antd
import { Table, Button, Modal, Input, Form, message, Select, Popconfirm, DatePicker, Space, Popover } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
// config
import config from '@config/config'
// fetch/REST API
import $api from '@http/$api'
import { useQuery } from 'react-query'
// components
import UserUpdate from '@components/AccessMonitoring/UserUpdate'
import UserCreate from '@components/AccessMonitoring/UserCreate'
// styles
import styled from 'styled-components'



const { Option } = Select

interface IProps {
    record: IAccessMonitoring
    visible: boolean
    // refetch: () => void
    closeUserListVisible: () => void
}

const UserList: React.FC<IProps> = (props) => {

    const [chosenUserData, setChosenUserData] = useState( {} as IAccessMonitoringUser)
    const [userUpdateVisible, setUserUpdateVisible] = useState(false)
    const [userCreateVisible, setUserCreateVisible] = useState(false)

    const { data, refetch } = useQuery<IAccessMonitoringUserList, Error>(
        'userList',
        async () => {
            return await $api.get(`/monitoring/users/get/${props.record.carrier_id}`)
        },
        {
            enabled: false,
            retry: 0,
            refetchIntervalInBackground: false,
            notifyOnChangeProps: ['data', 'refetch'],
            onSuccess: (res) => {
            },
            onError: (err) => {
                console.log('err', err)
                message.error('Ошибка подключения к серверу')
            },
        }
    )

    const closeUserUpdate = () => {
        setUserUpdateVisible(false)
    }
    const closeUserCreate = () => {
        setUserCreateVisible(false)
    }

    useEffect(() => {
        props.visible && refetch()
    }, [props.visible])

    // колонки для таблицы
    const columns: ColumnsType<IAccessMonitoringUser> = [

        // ROW NUMBER
        {
            title: '№',
            key: 'index',
            fixed: 'left',
            align: 'center',
            width:  50,
            render: (value, record, index) => <>{index + 1}</>
        },

        // login
        {
            title: 'Логин',
            key: 'login',
            align: 'center',
            dataIndex: 'login',
        },  

        // pay_date
        {
            title: 'Дата оплаты',
            key: 'pay_date',
            align: 'center',
            dataIndex: 'pay_date',
        },  

        // last_activity
        {
            title: 'Последняя активность',
            key: 'last_activity',
            align: 'center',
            dataIndex: 'last_activity',
        },  

         // EDIT ROW
         {
            title: (
                <Popover placement="topLeft" content={'Создать'}>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setUserCreateVisible(true)
                        }}
                    />
                </Popover>
            ),
            width: 70,
            render: (record: IAccessMonitoringUser) => {
                return (
                    <Popover placement="topLeft" content={'Изменить'}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            disabled={false}
                            onClick={() => {
                                setChosenUserData(record)
                                setUserUpdateVisible(true)
                            }}
                        />
                    </Popover>
                )
            },
            align: 'center',
            fixed: window.screen.width > 991 ? 'right' : false,
            key: 'editClient'
        },   
    ]


    return (
        <>
            <Modal
                mask={false}
                visible={props.visible}
                title={`Пользователи ${props.record.cname}`}
                onCancel={props.closeUserListVisible}
                footer={null}
                width={700}
            >
                <Table
                    columns={columns}
                    dataSource={data?.data}
                    bordered
                    rowKey={(record) => `${record.user_id}`}
                    pagination={false}
                    scroll={{y: `60vh`}}
                    style={{ borderBottom: '1px solid #ccc' }}
                />
            </Modal>
           
            <UserUpdate
                record={chosenUserData}
                visible={userUpdateVisible}
                closeUserUpdateModal={closeUserUpdate}
                refetch={refetch}
            />

            <UserCreate
                carrier={props.record}
                visible={userCreateVisible}
                closeUserCreateModal={closeUserCreate}
                refetch={refetch}
            />
        </>
    )
}

export default UserList
