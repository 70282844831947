/**
 * только русские и английские буквы + цифры и длинна от 2 до 20 символов
 * @param login  
 * @returns boolean
 */
export const nameValidate = (login: string) => {
    return /^[А-ЯA-Z0-9._%+-]{2,20}/i.test(login) 
} 

/**
 * Проверка корректности почты
 * @param email  
 * @returns boolean
 */
export const emailValidate = (email: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email) 
} 

/**
 * только английские буквы + цифры и длинна от 4 до 15 символов
 * @param pass 
 * @returns boolean
 */
export const passValidate = (pass: string) => {
    return /^[A-Z0-9._%+-]{4,15}/i.test(pass)
}

/**
 * Проверка корректности date ДД.ММ.ГГГГ
 * @param date  
 * @returns boolean
 */
export const dateValidate = (date: string) => {
    return /^[0-9]{2}.[0-9]{2}.[0-9]{4}$/.test(date)
}

/**
 * Проверка корректности date: ГГГГ-ММ-ДД
 * @param date  
 * @returns boolean
 */
export const standardDateValidate = (date: string) => {
    return /^[0-9]{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/.test(date)
}


/**
 * Проверка на длинну и на отсутствие левых символов
 * @param value  
 * @returns boolean
 */
export const stringNameValidate = (value: string) => {
    return /^[А-ЯA-Z0-9._%+-]{3,50}/i.test(value)
}


export const imeiValidate = (imei: string ) => {
    return /^[0-9]{15,16}$/.test(imei.toString())
}