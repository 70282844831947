import React, { useState, useEffect } from 'react'
import { Form, Select } from 'antd'
import { CheckOutlined, CloseOutlined, WifiOutlined } from '@ant-design/icons'
// Default settings
import config from '@config/config'
// styles
import styled from 'styled-components'

interface ISFCProps {
    name: string
    title: string
    values: string[]
    onChange: (value: string | number | boolean) => void
    w?: number
    iconType: string
    clientType: string
    clearInputField: boolean
}

const { Option } = Select

const FilterColumnSelect: React.FC<ISFCProps> = (props) => {
    const [form] = Form.useForm()

    // текущая дата
    const [currentDate, setCurrentDate] = useState(new Date())

    // обновляем состояние текущей даты
    useEffect(() => {
        let dateInterval: any
        if (props.iconType === 'color' && props.clientType === 'device') {
            dateInterval = props.iconType === 'color' && props.clientType === 'device' && setInterval(() => {
                setCurrentDate(new Date())
            }, 60000)
        }
        return () => {
            clearInterval(dateInterval)
        }
    }, [props.clientType, props.iconType])
   

    // значение нашего поля input если страница была перезагружена
    // если это начальное состояние "Device/Дата окончания поверки" по цвету, state будет другим
    const [inputValue, setInputValue] = useState(
        props.clientType === 'device' && props.iconType === 'color'
            ? localStorage.getItem('endDateColor')
            : JSON.parse(localStorage.getItem(`${props.clientType}Filters`) as string)[`${props.name}`] || '',
    )

    // если поступила команда от родителя, происходит очистка поля ввода
    useEffect(() => {
        setInputValue('')
        form.resetFields()
    }, [props.clearInputField, form])

    // клик мышки не распространяется на остальные компоненты под Input и далее
    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation()
    }

    return (
        <div>
            <div style={{ userSelect: 'none' }}>{props.title}</div>
            <hr />
            <Form form={form}>
                <Form.Item initialValue={inputValue} name="filter_column-select" style={{ margin: 0 }}>
                    {/* warning.js:6 Warning: `label` of `value` is not same as `label` in Select options. 
                    вылезает из-за отсутствия в Select параметра value*/}
                    <Select style={{ width: '100%' }} onChange={props.onChange} onClick={onClick}>
                        {props.values.map((value) => {
                            return (
                                <Option value={value} key={value}>
                                    {/* FOR EMPTY VALUE */}
                                    {value === '' && ''}

                                    {/* SERVICE */}
                                    {props.iconType === 'check' && value === 'true' && (
                                        <CheckOutlined style={{ color: 'green' }} />
                                    )}
                                    {props.iconType === 'check' && value === 'false' && (
                                        <CloseOutlined style={{ color: 'red' }} />
                                    )}

                                    {/* STATUS */}
                                    {props.iconType === 'wifi' && value >= '1' && (
                                        <WifiOutlined style={{ color: 'green' }} />
                                    )}
                                    {props.iconType === 'wifi' && value === '0' && (
                                        <WifiOutlined style={{ color: 'red' }} />
                                    )}

                                    {/* OS */}
                                    {props.iconType === 'defaultIcon' && value}

                                    {/* SENDING */}
                                    {props.iconType === 'check' && value === '1' && (
                                        <CheckOutlined style={{ color: 'green' }} />
                                    )}
                                    {props.iconType === 'check' && value === '0' && (
                                        <CloseOutlined style={{ color: 'red' }} />
                                    )}

                                    {/* TYPE DEVICE - DEVICE TYPE*/}
                                    {props.iconType === 'text' && value === '0' && <p>УСПД</p>}
                                    {props.iconType === 'text' && value === '1' && <p>Расходомер</p>}
                                    {props.iconType === 'text' && value === '2' && <p>ДТС</p>}
                                    {props.iconType === 'text' && value === '3' && <p>Уровнемер</p>}

                                    {/* TYPE DEVICE - END DATE */}
                                    {props.iconType === 'color' && value === '' && ''}
                                    {/* ЖЕЛТЫЙ */}
                                    {props.iconType === 'color' && value === 'yellow' && (
                                        <ColorSelectStyled
                                            style={{
                                                color: config.deviceEndDataColor.yellow,
                                            }}
                                        >
                                            16 - 45
                                        </ColorSelectStyled>
                                    )}
                                    {/* ОРАНЖЕВЫЙ */}
                                    {props.iconType === 'color' && value === 'orange' && (
                                        <ColorSelectStyled
                                            style={{
                                                color: config.deviceEndDataColor.orange,
                                            }}
                                        >
                                            1 - 15
                                        </ColorSelectStyled>
                                    )}
                                    {/* КРАСНЫЙ */}
                                    {props.iconType === 'color' && value === 'red' && (
                                        <ColorSelectStyled
                                            style={{
                                                color: config.deviceEndDataColor.red,
                                            }}
                                        >
                                            {currentDate.getDate()}.{String(currentDate.getMonth() + 1).padStart(2, '0')}.
                                            {currentDate.getFullYear()} и ранее
                                        </ColorSelectStyled>
                                    )}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    )
}

const ColorSelectStyled = styled.p`
    padding: 0;
    margin: 0;
    font-weight: bold;
    text-align: center;
`

export default FilterColumnSelect
