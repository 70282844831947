import React, { useState, useEffect } from 'react'
import { IClient } from '@interfaces/IClients'
import config from '@config/config'
import { Button, Modal, Input, Form, Switch, message, Popconfirm } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import $api from '@http/$api'
import styled from 'styled-components'

interface ICCProps {
    visible: boolean
    refetch: () => void
    clientType: string
    closeCreateClientModal: () => void
    showCreateClientModal: () => void
}

const ClientCreate: React.FC<ICCProps> = (props) => {

    const [form] = Form.useForm();

    const [clientData, setClientData] = useState({} as IClient)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // стандартные значения для полей: видимость, и сервис
        setClientData({ ...clientData, visible: true, service_status: false })
        return () => {
            // если окно скрыто, сбрасываем его сожержимое
            !props.visible && setClientData({} as IClient)
        }
    }, [props.visible])


    // подставляем данные из input
    const changeClientData = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientData({ ...clientData, [event.target.name]: event.target.value })
    }

    // подставляем данные из переключателей
    const changeVisible = () => {
        setClientData({ ...clientData, visible: !clientData.visible })
    }
    const changeServiceStatus = () => {
        setClientData({ ...clientData, service_status: !clientData.service_status })
    }

    // запрещаем набор некоторых символов в input type=number
    const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) => {
        ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()
    }

    const clearFields = () => {
        setLoading(false)
        form.resetFields() 
        setClientData({} as IClient)
    }

    //  тут отправим данные на сервер
    const sendNewClientData = async () => {
        
        if(!clientData.number_uspd) {
            message.error('Заполните УСПД')
            return
        }
        if(!clientData.ip) {
            message.error('Заполните IP')
            return
        }
        if(!clientData.vpn_client) {
            message.error('Заполните VPN')
            return
        }
        if(!clientData.company) {
            message.error('Заполните Название Компании')
            return
        }
        // загрузка началась
        setLoading(true)
        await $api.post(`${config.serverURL}/list/addclient`, {
            type: props.clientType.toString() === 'uspd' ? '1' : props.clientType.toString() === 'tank' ? '2' : '3',
            ip: clientData.ip ?? '',
            vpn_client: +clientData.vpn_client,
            name: clientData.name ?? '',
            company: clientData.company ?? '',
            city: clientData.city ?? '',
            number_uspd: +clientData.number_uspd,
            iccid: clientData.iccid ?? '',
            number: clientData.number ?? '',
            visible: !!clientData.visible ?? false,
            service_status: !!clientData.service_status ?? false,
        }).catch((err) => {
            setLoading(false)
            message.error('Не удалось создать!')
            throw new Error(err)
        })


        // загрузка звершилась удачно
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Создание прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()
        
        // закрываем окошко обновления клиента
        props.closeCreateClientModal()
        
        // сбрасываем значения из полей ввода
        clearFields()

        return
    }

    return (
        <Modal
            mask={false}
            visible={props.visible}
            title="Окно создания"
            onCancel={() => {
                props.closeCreateClientModal()
                clearFields()
            }}
            footer={[
                // CREATE 
                <Popconfirm
                    title="Добавить новое устройство?"
                    onConfirm={sendNewClientData}
                    onCancel={() => {}}
                    okText="Да"
                    cancelText="Нет"
                    icon={<PlusOutlined style={{ color: '#1890ff'}}/>}
                    key="create"
                >
                    <Button 
                        style={{ marginRight: '10px'}}
                        type="primary" 
                        htmlType="submit"
                        loading={loading} 
                    >
                        Создать
                    </Button>
                </Popconfirm>,   

                // CANCEL 
                <Button 
                    key="back" 
                    onClick={() => {
                        props.closeCreateClientModal()
                        clearFields()
                    }}
                >
                    Отмена
                </Button>
            ]}
        >
            <Form form={form}>
                <FormItemStyled label="Компания" name="company" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <InputStyled
                        autoComplete="off"
                        type="text"
                        name="company"
                        placeholder='ООО "Наименование Компании"'
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="Наименование" name='name'>
                    <InputStyled
                        autoComplete="off"
                        type="text"
                        name="name"
                        placeholder=''
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="УСПД" name="number_uspd" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <InputStyled
                        autoComplete="off"
                        type="number"
                        name="number_uspd"
                        placeholder="123"
                        onChange={changeClientData}
                        onKeyDown={blockInvalidChar}
                    />
                </FormItemStyled>
                <FormItemStyled label="Город" name='city'>
                    <InputStyled
                        autoComplete="off"
                        type="text"
                        name="city"
                        placeholder="Москва"
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="IP" name="ip" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <InputStyled
                        autoComplete="off"
                        type="text"
                        name="ip"
                        placeholder='1.1.1.1'
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="VPN" name="vpn_client" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <InputStyled
                        autoComplete="off"
                        type="number"
                        name="vpn_client"
                        placeholder='123'
                        onChange={changeClientData}
                        onKeyDown={blockInvalidChar}
                    />
                </FormItemStyled>
                <FormItemStyled label="iccid" name='iccid'>
                    <InputStyled
                        autoComplete="off"
                        type="text"
                        name="iccid"
                        placeholder='897010261234567890'
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="Номер" name='number'>
                    <InputStyled
                        autoComplete="off"
                        type="telephone"
                        name="number"
                        placeholder="+79123456789"
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="Сервис" name='service'>
                    <Switch
                        checked={clientData.service_status}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={changeServiceStatus}
                    />
                </FormItemStyled>
                <FormItemStyled label="Видимость" name='visible'>
                    <Switch
                        checked={clientData.visible}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={changeVisible}
                        // defaultChecked={true}
                    />
                </FormItemStyled>                
            </Form>
        </Modal>
    )
}

const InputStyled = styled(Input)``

const FormItemStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 80%;
    }
`

export default ClientCreate
