import React from 'react'
import Login from '@pages/Login'
import ClientsListPage from '@pages/ClientsList'
import DevicesListPage from '@pages/DeviceList'
import AccessMonitoringPage from '@pages/AccessMonitoring'

export interface IRoute {
    path: string
    component: React.ComponentType
}

// список страниц
export enum RoutePaths {
    LOGIN = 'login',
    CLIENTS_LIST = 'clients',
    DEVICES_LIST = 'devices',
    ACCESS_MONITORING = 'monitoring_t',
}

// экспортируем публичные роуты, которые доступны без авторизации
export const publicRoutes: IRoute[] = [
    { path: RoutePaths.LOGIN, component: Login }
]

// роуты, доступные только авторизованным пользователям
export const privateRoutes: IRoute[] = [
    { path: RoutePaths.CLIENTS_LIST, component: ClientsListPage },
    { path: RoutePaths.DEVICES_LIST, component: DevicesListPage },
    { path: RoutePaths.ACCESS_MONITORING, component: AccessMonitoringPage }
]
