import $api from '@http/$api'
import { IAccessMonitoring } from '@interfaces/IAccessMonitoring'

const getList = async () => {
    const response = await $api.get<IAccessMonitoring[]>('/monitoring/list')
    return response.data
}

const MonitoringAPI = {
    getList,
}

export default MonitoringAPI
