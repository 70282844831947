import React, {useState, useEffect} from 'react';
import { Form, Input } from 'antd'


interface ITFCProps {
    name: string
    title: string
    clientType: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    clearInputField: boolean
    w?: number
}

const FilterColumnInput: React.FC<ITFCProps> = (props) => {

    const [form] = Form.useForm();

    // значение нашего поля input если страница была перезагружена
    const [inputValue, setInputValue] = useState(JSON.parse(localStorage.getItem(`${props.clientType}Filters`) as string)[`${props.name}`] || '')
    
    // клик мышки не распространяется на остальные компоненты под Input и далее
    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation()
    }
    
    // очистить поле ввода, если поступила команда очистить фильтры
    useEffect(() => {
        setInputValue('')
        form.resetFields() 
    }, [props.clearInputField, form])

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{userSelect: 'none'}}>{props.title}</div>
            <hr />
            <Form form={form}>
                {/* TYPE DEVICE */}
                <Form.Item initialValue={inputValue} name='filter_column' style={{margin: '0'}}>
                    <Input
                        name={props.name}
                        style={{width: '100%'}} 
                        onChange={props.onChange}                  
                        onClick={onClick} 
                        placeholder={''}
                        key={props.name.toString()}
                    />
                </Form.Item>
            </Form>
        </div>
    )
}

export default FilterColumnInput;