import React, { useEffect, useState } from 'react'
import { Input, Tooltip, message } from 'antd'
import { InfoCircleOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Password from 'antd/lib/input/Password';

interface IProps {
    autoComplete?: string | undefined
    type?: string | undefined
    name?: string | undefined
    label?: string | undefined
    placeholder?: string | undefined
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
    value?: string | number | readonly string[] | undefined
    status?: "" | "error" | "warning" | undefined
    validator?: (val: string) => boolean
    isValid?: (name: string, valueValidState: boolean) => void
    maxLength?: number | undefined
    info?: {
        text?: string | undefined
        style?: React.CSSProperties | undefined
    }
}

const CustomInput = (props: IProps) => {

    const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
    const [passwordVisible, setPasswordVisible] = useState<boolean | undefined>(false)

    useEffect(() => {
        // если поле не валидно, оповещаем юзера
        isValid === false && message.error(`Неверное значение для поля: ${props.label}`)

        // отправляем родителю состояние поля
        props.name 
        && !!props.isValid 
        && isValid !== undefined
        && props.isValid(props.name, isValid)
    }, [isValid])

    // если поле теряет таргет, валидируем введенные данные
    const onBlurHandle = (event: React.FocusEvent<HTMLInputElement>) => {
        props.validator && setIsValid(props.validator(event.target.value)) 
    }

    // при нажатии на поле активируем валидацию
    const onclickHandle = () => {
        setIsValid(undefined)
    }

    // Если поле используется для пароля, 
    // добавляем функционал раскрытия и скрытия символов
    const showPass = () => {
        setPasswordVisible(true)
    }
    const hidePass = () => {
        setPasswordVisible(false)
    }

    return (
        <Input
            autoComplete={props.autoComplete || ''}
            defaultValue=''
            type={passwordVisible === true ? 'text' : props.type || "text"}
            name={props.name}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={onBlurHandle}
            onClick={onclickHandle}
            value={props.value}
            status={isValid === false ? 'error': props.status || ""}
            maxLength={props.maxLength}
            suffix={ props.info 
                ? (
                    <>
                        { 
                            props.type === 'password' ?
                            <>
                                <EyeInvisibleOutlined onClick={hidePass} hidden={!passwordVisible}/>
                                <EyeOutlined onClick={showPass} hidden={passwordVisible}/>
                            </>
                            : undefined
                        }
                        <Tooltip title={props.info?.text}>
                            <InfoCircleOutlined style={props.info?.style || { color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip> 
                    </>
                )
                : undefined
            }         
        />
    )
}

export { CustomInput }