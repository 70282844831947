const config = {

    // AUTH/CLINET_DATA URL
    serverURL: 'https://clients.zahp.ru',
    // serverURL: 'http://localhost:1323',
    
    // config useQuery for get Clients Data USPD/Conteiner/Tank
    useQueryClientsDataConfig: {
        enabled: true,
        retry: 0,
        refetchInterval: 10000,
        refetchIntervalInBackground: true,
    },
    useQueryDeviceDataConfig: {
        enabled: true,
        retry: 0,
        refetchIntervalInBackground: false,
    },
    useQueryAccessMonitoringDataConfig: {
        enabled: true,
        retry: 0,
        refetchIntervalInBackground: false,
    },
    
    // config useQuery for AUTH
    useQueryAuthConfig: {
        enabled: false,
        retry: 1,
    },

    deviceEndDataColor: {
        normal: '',
        yellow: 'rgba(255, 127, 0, 1)',
        orange: 'rgba(255, 0, 0, 1)',
        red: 'rgba(190, 0, 0, 1)',
        incorrect: 'rgba(100, 100, 100, 0.6)'
    }
}

export default config
