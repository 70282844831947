import React, { useState, useEffect, useCallback, useContext } from 'react'
// Use Query
import { useQuery } from 'react-query'
// device AXIOS methods
import devicesAPI from '@api/DevicesAPI'
// Default settings
import config from '@config/config'
// Context
import { UserContext } from '@context/UserContext'
// ANTD
import { Table, Button, message, Spin, Input, Form } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import {
    SettingOutlined,
    EditOutlined,
    PlusOutlined,
    RetweetOutlined,
    ClearOutlined
} from '@ant-design/icons'
// dateParser
import { dateToDays } from '@utils/DateParser'
// Components
import FilterColumnInput from '@components/UI/FilterColumn-Input'
import FilterColumnSelect from '@components/UI/FilterColumn-Select'
import DeviceColumnListEdit from '@components/DevicesList/DeviceColumnListEdit'
import DeviceCreate from '@components/DevicesList/DeviceCreate'
import DeviceUpdateDelete from '@components/DevicesList/DeviceUpdate&Delete'
// styled
import styled from 'styled-components'

// /////////////////////////////////////////////////
// Components
import CompanyCreate from '@components/AccessMonitoring/CompanyCreate'
// import { list } from "../temp/accessMonitoringTempData" // temp data
import Row from '@components/AccessMonitoring/Row'
// Interfaces
import { IAccessMonitoring, IAccessMonitoringTank } from '@interfaces/IAccessMonitoring'
import MonitoringAPI from '@api/MonitoringAPI'
// /////////////////////////////////////////////////

interface IFilterParams {
    carrier_name: string
    tank_name: string
}


const AccessMonitoringBasisT: React.FC = () => {

    // контекст тут нужен для блокировки функционала по добавлению/редактированию/удалению устрйоств по роли
    const auth = useContext(UserContext)

    // модальное окно создания новой компании
    const [visibleCreateCompanyModal, setVisibleCreateCompanyModal] = useState<boolean>(false)
    // отфильтрованные данные (идут на отрисовку)
    const [filteredData, setFilteredData] = useState<IAccessMonitoring[]>()
    // значения для фильтрации от пользователя
    const [filterParams, setFilterParams] = useState<IFilterParams>({carrier_name: '', tank_name: ''})

    // забор данных с сервера
    const { data, refetch } = useQuery<IAccessMonitoring[], Error>(
        'monitoringList',
        async () => {
            return await MonitoringAPI.getList()
        },
        {
            ...config.useQueryAccessMonitoringDataConfig,
            notifyOnChangeProps: ['data', 'refetch'],
            onSuccess: (res) => {
                filtration(res)
            },
            onError: (err) => {
                console.log('err', err)
                message.error('Ошибка подключения к серверу')
            }
        }
    )
    
    /**
     * Фильтрация данных на основе информации из полей фильтра
     * Данные в результате выполнения функции подставляются в таблицу на отрисовку
     * @param data - данные с сервера
     */
    const filtration = (data: IAccessMonitoring[]) => {

        let result: IAccessMonitoring[]

        // проверяем совпадение по имени
        result = data.filter(carrier => {
            return carrier.cname.toLowerCase().includes(filterParams.carrier_name.toLowerCase().trim())
        })

        // если в поиске по названию цистерны что-то написано, то проверяем
        // иначе не тратим ресурсы на проверку
        if(filterParams.tank_name) {
            result.map((carrier, index) => {
                result[index] = {
                    ...carrier,
                    data: carrier.data.filter(tank => {
                        return tank.name.toLowerCase().includes(filterParams.tank_name.toLowerCase().trim())
                    })
                }
            })
            
            // возвращаем тоьлко тех перевозчикво, в которых есть цистерны подходящие под фильтр
            result = result.filter(carrier => {
                return carrier.data.length > 0
            })
        }

        // закидываем в локальный стэйт
        setFilteredData(result)
    }

    // при изменении фальтра, применяем фильтрацию к данным
    useEffect(() => {
        data && filtration(data)
    }, [filterParams])

    const closeCompanyCreateModal = () => {
        setVisibleCreateCompanyModal(false)
    }

    // обновляем стэйт с параметрами фильтра при изменении
    const changeFilterParams = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterParams({...filterParams, [event.target.name]: event.target.value})
    }

    // колонки для таблицы
    const columns: ColumnsType<IAccessMonitoring> = [

        // ROW NUMBER
        {
            title: '',
            key: 'index',
            fixed: 'left',
            align: 'center',
            width:  60,
            render: (value, record, index) => <>{index + 1}</>
        },

        // COMPANY NAME
        {
            // title: 'name',
            key: 'name',
            // fixed: window.screen.width > 991 ? 'left' : false,
            // align: 'center',
            render: (value, record: IAccessMonitoring, index) => {
                return (
                    <Row 
                        record={record} 
                        refetch={refetch}
                    />
                )
            },
            // sorter: (a: IAccessMonitoring, b: IAccessMonitoring) => a.carrier_id - b.carrier_id,
            sortOrder: 'ascend'  
        },  
    ]

    return (
        <AccessMonitStyleWrapper>
            {false ? (
                <SpinnerStyle>
                    <Spin size="large" />
                </SpinnerStyle>
            ) : (
                <div>
                     <SettingsPanel>

                        {/* FILTERS */}
                        <Form.Item label="Организация">
                            <Input
                                autoComplete="off"
                                type="text"
                                placeholder='ООО "Заводхимпром"'
                                name="carrier_name"
                                onChange={changeFilterParams}
                                value={filterParams.carrier_name}
                            />
                        </Form.Item >
                        <Form.Item label="Цистерна" style={{margin: '0 0 0 20px'}}>
                            <Input
                                autoComplete="off"
                                type="text"
                                placeholder='AA 1234 56 RUS'
                                name="tank_name"
                                onChange={changeFilterParams}
                                value={filterParams.tank_name}
                            />
                        </Form.Item >

                        {/* CLEAR FILTERS */}
                        <Button
                            style={{margin: '0 auto 0 20px'}}
                            type="primary"
                            onClick={() => setFilterParams({carrier_name: '', tank_name: ''})}
                        > 
                            {window.screen.width > 450 ? 'Очистить фильтры' : ''} <ClearOutlined />
                        </Button>

                        {/* ADD NEW ORGANIZATION */}
                        <Button
                            type="primary"
                            disabled={auth.role !== '2'}
                            onClick={() => {
                                setVisibleCreateCompanyModal(true)
                            }}
                        > 
                            {window.screen.width > 450 ? 'Добавить организацию' : ''} <PlusOutlined/>
                        </Button>
                    </SettingsPanel>
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        bordered
                        rowKey={(record) => {
                            return (
                                `${record.carrier_id}`
                                + `${record.cname}`
                            )
                        }}
                        pagination={{ 
                            position: ['topLeft'], 
                            pageSizeOptions: [9, 10, 20, 50, 100, 150, 200], 
                            defaultPageSize: 9
                        }}
                        scroll={{ x: 1500, y: window.screen.width > 991 ? 'calc(100vh - 150px)' : 'calc(100vh - 360px)'}}
                        style={{ borderBottom: '1px solid #ccc' }}
                    />
                   

                    <CompanyCreate 
                        visible={visibleCreateCompanyModal}
                        refetch={refetch}
                        closeCompanyCreateModal={closeCompanyCreateModal}
                    />
                </div>
            )}
           
        </AccessMonitStyleWrapper>
    )
}


const AccessMonitStyleWrapper = styled.div`
    .ant-table-thead > tr > th {
        padding: 0;
        border: none;
    }
`

// нижняя панель инструментов таблицы (создать, обновить данные, очистить фильтры)
const SettingsPanel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 8px 1%;

    @media (max-width: 450px) {
        span {
            margin: 0 !important;
        }
    }

    > .ant-form-item { 
        margin: 0;
    }
`



// стили спинера-заглушки, если данные не прогрузились с сервера
const SpinnerStyle = styled.div`
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export default AccessMonitoringBasisT
