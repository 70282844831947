import React from 'react'
// React query
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
// Context
import { UserContext } from '@context/UserContext'
// Auth
import { useAuth } from '@hooks/useAuth'
// Components
import Header from '@components/Header/Header'
import AppRouter from '@components/AppRouter/AppRouter'
// Styles
import '@styles/app.css'
import { Layout } from 'antd'

// Создаем клиента для React query
const queryClient = new QueryClient({
    // ...конфиги для наших query элементов
})

const App: React.FC = () => {
    // забираем все методы и переменные из хука аутентификации
    const { login, logout, refresh, userName, role, isAuthenticated } = useAuth()

    return (
        <QueryClientProvider client={queryClient}>
            <UserContext.Provider
                value={{
                    userName,
                    role,
                    login,
                    logout,
                    refresh,
                    isAuthenticated,
                }}
            >
                <Layout >
                    <Header />
                    <Layout.Content>
                        <AppRouter />
                    </Layout.Content>
                </Layout>
            </UserContext.Provider>
            {/* <ReactQueryDevtools position="bottom-left" /> */}
        </QueryClientProvider>
    )
}

export default App
