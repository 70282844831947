import React, { useState, useEffect } from 'react'
import { Menu, Layout, Row, MenuProps } from 'antd'
import { MenuItemType } from 'rc-menu/lib/interface'
import ClientListUspd from '@components/ClientList/ClientList-Uspd'
import ClientListTank from '@components/ClientList/ClientList-Tank'
import ClientListContainer from '@components/ClientList/ClientList-Container'
import '@styles/clientsList.css'
import styled from 'styled-components'

const items: MenuProps['items'] = [
    {
        label: 'УСПД',
        key: 'uspd',
    },
    {
        label: 'Цистерны',
        key: 'tank',
    },
    {
        label: 'Контейнеры',
        key: 'conteiner',
    },
]

const ClientsListPage: React.FC = () => {
    const [clientsPage, setClientsPage] = useState(localStorage.getItem('clientsPage') ?? 'uspd')
    // console.log('LIST RENDER')

    useEffect(() => {
        localStorage.setItem('clientsPage', clientsPage)
    }, [clientsPage])

    const onClick: MenuProps['onClick'] = (item: MenuItemType) => {
        setClientsPage(item.key.toString())
    }
    
    return (
        <Layout>
            <Layout.Header className="header" style={{padding: '0 5%', height: '40px', backgroundColor: '#444'}} >
                <Row style={{ height: '40px'}}>
                    <MenuStyled
                        theme="dark"
                        mode="horizontal"
                        items={items}
                        onClick={onClick}
                        selectedKeys={[clientsPage]}
                    ></MenuStyled>
                </Row>
            </Layout.Header>
            <Layout.Content>
                {clientsPage === 'uspd' && <ClientListUspd />}
                {clientsPage === 'tank' && <ClientListTank />}
                {clientsPage === 'conteiner' && <ClientListContainer />}
            </Layout.Content>
        </Layout>
    )
}

const MenuStyled = styled(Menu)`
    background-color: #444 !important;
    
    .ant-menu-item {
        margin: 5px 5px 0 0 !important;
        max-height: 30px;

        .ant-menu-title-content {
            display: flex;
            align-items: center;
            max-height: 30px;
        }
    }
`

export default ClientsListPage
