import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { UserContext } from '@context/UserContext'
import { RoutePaths } from '@routes/Routes'
import '@styles/header.css'
const logo = require('@img/header/logo.ico')



const Header: React.FC = () => {
    const [globalPage, setGlobalPage] = useState(localStorage.getItem('globalPage') ?? 'clients')

    const auth = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.setItem('globalPage', globalPage)
    }, [globalPage])

    if (auth.isAuthenticated) {
        return (
            <HeaderStyled>
                <Navbar>
                    <button
                        className='clients-btn'
                        onClick={() => {
                            setGlobalPage(RoutePaths.CLIENTS_LIST)
                            navigate(RoutePaths.CLIENTS_LIST)
                        }}
                        style={globalPage === `${RoutePaths.CLIENTS_LIST}` ? { backgroundColor: '#1890ff', color: '#fff' } : {}}
                    >Клиенты</button>
                    <button
                        className='devices-btn'
                        onClick={() => {
                            setGlobalPage(RoutePaths.DEVICES_LIST)
                            navigate(RoutePaths.DEVICES_LIST)
                        }}
                        style={globalPage === `${RoutePaths.DEVICES_LIST}` ? { backgroundColor: '#1890ff', color: '#fff' } : {}}
                    >Устройства</button>
                    <button
                        hidden={auth.role !== '2' ? true : false}
                        className='access-monitoring-btn'
                        onClick={() => {
                            setGlobalPage(RoutePaths.ACCESS_MONITORING)
                            navigate(RoutePaths.ACCESS_MONITORING)
                        }}
                        style={globalPage === `${RoutePaths.ACCESS_MONITORING}` ? { backgroundColor: '#1890ff', color: '#fff' } : {}}
                    >Мониторинг</button>
                </Navbar>
                <img className="logo" src={logo} alt="img" />
                <div>{auth.userName}</div>
                <button onClick={auth.logout}>Выход</button>
            </HeaderStyled>
        )
    }

    return (
        <>
            {/* <button onClick={() => navigate(RoutePaths.LOGIN)}></button> */}
            {/* <button onClick={() => navigate(RoutePaths.LOGIN)}>Вход</button> */}
        </>
    )
}

export default Header

const HeaderStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 5%;
    height: 48px;
    background-color: #383838;

    > * {
        /* margin: 0 15px; */
        color: #fff;
    }

    button {
        background-color: #383838;
        cursor: pointer;
        height: 100%;
        transition: background-color 0.2s linear, color 0.2s linear;
        margin: 0 0 0 20px;
        padding: 0 15px;
        color: #fff;

        :hover {
            background-color: #1890ff;
            /* color: #000; */
        }
    }

    > img {
        height: auto;
        width: 100%;
        margin: 0 15px;
    }

    @media (max-width: 440px) {
        height: 80px;
        padding: 0 5% 0 8%;
    }
`

const Navbar = styled.div`
    flex: 1;
    color: #fff;

    > * {
        margin: 0 15px;
        color: #fff;
    }

    > button {
        background-color: #383838;
        cursor: pointer;
        height: 100%;
        transition: background-color 0.2s linear, color 0.2s linear;
        margin: 5px 10px 2px 0 !important;
        padding: 4px 18px;
        color: #ccc;
        font-size: 16px;

        :hover {
            background-color: #1890ff;
            color: #fff;
        }

        @media (max-width: 440px) {
            padding: 5px 22px;
            margin: 1px 0 !important;
        }
    }

    > .devices-btn {
        margin-top: 5px;
    }
`
