import { createContext } from 'react'
import { IUserContext } from '@interfaces/IAuth'

export const UserContext = createContext<IUserContext>({
    userName: null,
    role: '',
    login: async () => {
        return {
            access_token: '',
            refresh_token: '',
            role: ''
        }
    },
    logout: () => {},
    refresh: async () => {
        return {
            access_token: '',
            refresh_token: '',
            role: ''
        }
    },
    isAuthenticated: false,
})
