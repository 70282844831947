import React, { useState, useEffect } from 'react'
import { IDevice, IMassflow, IMassflowType } from '@interfaces/IDevices'
import config from '@config/config'
import { Button, Modal, Input, Form, message, Select, Popconfirm, Space, DatePicker } from 'antd'
import {
    ClearOutlined,
    PlusOutlined
} from '@ant-design/icons'
import type { DatePickerProps } from 'antd';
import { customDateFormatToISO } from '@utils/DateParser';
import moment from 'moment';
import $api from '@http/$api'
import styled from 'styled-components'
import { dateValidate } from '@utils/validation'


const { Option } = Select


interface ICDProps {
    visible: boolean
    refetch: () => void
    closeCreateDeviceModal: () => void
    showCreateDeviceModal: () => void
}


const DeviceCreate: React.FC<ICDProps> = (props) => {

    const [form] = Form.useForm();

    const [deviceData, setDeviceData] = useState({} as IDevice)
    const [loading, setLoading] = useState(false)
    const [massflowData, setMassflowData] = useState([] as IMassflow[])
    const [massflowTypeList, setMassflowTypeList] = useState([] as IMassflowType[])
    const [deviceMassflowId, setDeviceMassflowId] = useState('')
    const [openMassflowSelectList, setOpenMassflowSelectList] = useState(false)


    // подставляем данные из input
    const changeDeviceDataFromInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceData({ ...deviceData, [event.target.name]: event.target.value })
    }

    // const changeDeviceEndDate: DatePickerProps['onChange'] = (date, dateString) => {
    //     dateString && setDeviceData({ ...deviceData, end_date: dateString})
    // }

    const clearDeviceEndDate = () => {
        form.resetFields(['end_date']) 
        setDeviceData({ ...deviceData, end_date: '00.00.0000'})
    }

    useEffect(() => {
        clearDeviceEndDate()
    },[])
        
    
    // запрещаем набор некоторых символов в input type=number
    const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) => {
        ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()
    }

    const clearFields = () => {
        setLoading(false)
        form.resetFields() 
        setDeviceData({} as IDevice)
        setDeviceMassflowId('')
    }

    // Запрашиваем список типов Расходомеров
    const getMassflowTypes = async () => {
        // начало загрузки любых данных с сервера
        setLoading(true)
        
        await $api.get(`${config.serverURL}/device/getmftypes`)
            .then((response) => {
                setLoading(false)
                setMassflowTypeList(response.data)
            })
            .catch((err) => {
                setLoading(false)
                message.error("Не удалось получить список доступных типов расходомеров!")
                message.error(err.response.data.err)
                throw new Error(err.response.data.err)
            })
    }
        
    // Запрашиваем список доступных расходомеров по определенному USPD
    const getAvailableMassflowList = async () => {
        // сбрасываем данные из select SERIAL NUMBER
        form.resetFields(['id_massflow']) 
            
        // начало загрузки любых данных с сервера
        setLoading(true)

        await $api.get(`${config.serverURL}/device/dts/getmf?uspd=` + deviceData.uspd_number)
            .then((response) => {
                if(response.data.length > 0) {
                    setMassflowData(response.data)
                    setLoading(false)
                    setOpenMassflowSelectList(true)
                    message.success(`Доступно расходомеров: ${response.data.length}`)
                } else {
                    setLoading(false)
                    setOpenMassflowSelectList(false)
                    message.error(`Для УСПД № ${deviceData.uspd_number} отсутствуют доступные расходомеры!`)
                }
            })
            .catch((err) => {
                setLoading(false)
                setOpenMassflowSelectList(false)
                message.error(`Для УСПД № ${deviceData.uspd_number} отсутствуют доступные расходомеры!`)
                message.error(err.response.data.err)
                throw new Error(err.response.data.err)
            })
    }
        
    //  Отправляем на сервер данные для создания нового устрйоства
    const createNewDevice = async () => {
            // в соответствии с типом выбранного устрйоства формируем путь к этому устройству на сервере
        const typeDevice = deviceData.type_device === 1 ? 'mf' : deviceData.type_device === 2 ? 'dts' : 'level'

        // загрузка началась
        setLoading(true)
        await $api.post(`${config.serverURL}/device/${typeDevice}/insert `, {
            id_dts: +deviceData.id, // тупо порядковый номер в каждой таблице отдельно level(1 - 26) dts(1 - 296) MF(1 - 618) update/delete
            id_level: +deviceData.id, // тупо порядковый номер в каждой таблице отдельно level(1 - 26) dts(1 - 296) MF(1 - 618) update/delete
            id_massflow: typeDevice === 'mf' ? +deviceData.id : +deviceData.id_massflow, // если выбрали расходомер, id_massflow тут будет = deviceData.id
            uspd_number: deviceData.uspd_number, // порядковый номер УСПД в таблице complex: uspd_number = complex (1 - 234)  insert/update
            id_uspd: deviceData.id_uspd, // БАЗИС 2006М(1) или БАЗИС-Т(0)
            type_massflow: deviceMassflowId.toString(), // Тип расходомера требуется для таблицы расходомеров insert/update
            mark_device: deviceData.mark, // mark для расходомера insert/update
            mark_level: deviceData.mark, // mark для уровнеметра insert/update
            mark_dts: deviceData.mark, // mark датчика термосопротивления insert/update
            serial_number: deviceData.serial_number, // серийный номер insert/update
            end_date: deviceData.end_date, // датаокончания поверки insert/update
        }).catch((err) => {
            setLoading(false)
            message.error('Не удалось создать!')
            message.error(err.response.data.err)
            throw new Error(err.response.data.err)
        })
        
        // загрузка звершилась удачно
        setLoading(false)
        
        // выводим сообщение об успешном обновлении
        message.success('Создание прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // закрываем окошко обновления клиента
        props.closeCreateDeviceModal()

        // очищаем поля
        clearFields()

        return
    }
   

    // раскрываем/скрываем разные поля формы в зависимости от типа устройства
    useEffect(() => {
        // если меняется TYPE DEVICE скрываем поле SERIAL NUMBER для ДТС
        if(deviceData.type_device !== 2) setOpenMassflowSelectList(false) 
        // если TYPE DEVICE === Расходомер запрашиваем доступные типы расходомеров
        if(deviceData.type_device === 1) {
          getMassflowTypes()
        } 
        // если выбран уровнемер, то id_uspd всегда будет БАЗИС-Т
        // if(deviceData.type_device === 3) {
        //     setDeviceData(deviceData => ({ ...deviceData, id_uspd: 0}))
        // } else {
        //     setDeviceData(deviceData => ({ ...deviceData, id_uspd: 1}))
        // }
    }, [deviceData.type_device])


    return (
        <ModalStyled
            mask={false}
            visible={props.visible}
            title="Добавить новое устройство"
            onCancel={ ()=> {
                clearFields()
                props.closeCreateDeviceModal()
            }}
            footer={null}
        >
            <Form form={form}>
                 {/* TYPE DEVICE */}
                 <FormItemSelectStyled label="Тип устройства" name='type_device'>
                    <Select 
                        value={deviceData.type_device} 
                        onChange={(typeDevice) => {
                            setDeviceData({ ...deviceData, ['type_device' as keyof IDevice]: typeDevice })
                        }}
                    >
                        <Option value={1} key="mf">Расходомер</Option>
                        <Option value={2} key="dts">ДТС</Option>
                        <Option value={3} key="level">Уровнемер</Option>
                    </Select>
                    <CustomLabelStyled style={{ display: `${deviceData.type_device === 1 ? '' : 'none'}`}} >Тип:</CustomLabelStyled>
                    <Select 
                        style={{ display: `${deviceData.type_device === 1 ? '' : 'none'}`}} 
                        onChange={(typeMassflow) => {
                            setDeviceData({ ...deviceData, ['type_massflow' as keyof IDevice]: typeMassflow.value })
                            setDeviceMassflowId(typeMassflow.key)
                        }}
                        labelInValue
                    >
                        {massflowTypeList.map((massflowType) => {
                            return (
                                <Option value={massflowType.type} key={massflowType.id_type}>
                                    {massflowType.type}
                                </Option>
                            )
                        })}
                    </Select>
                </FormItemSelectStyled>
                                
                {/* USPD */}
                <FormItemUSPDStyled label="УСПД" name="uspd_number">
                    <Input
                        autoComplete="off"
                        type="number"
                        name="uspd_number"
                        placeholder='123'
                        onChange={changeDeviceDataFromInput}
                        onKeyDown={blockInvalidChar}
                    />
                    <Button 
                        disabled={deviceData.type_device !== 2}
                        type="primary" 
                        htmlType="submit"
                        name='check_btn'
                        loading={loading} 
                        onClick={getAvailableMassflowList}
                        style={{display: `${deviceData.type_device !== 2 ? 'none' : ''}`}}
                    >
                        {window.screen.width > 991 ? 'Проверить' : 'Пр.'}
                    </Button>
                    
                    <CustomLabelStyled>Тип:</CustomLabelStyled>
                    <Select 
                        // value={deviceData.id_uspd}
                        // defaultValue={deviceData.id_uspd}
                        onChange={(typeUspd) => {
                            setDeviceData({ ...deviceData, id_uspd: typeUspd })
                        }}
                    >
                        <Option value={0} key="bazis-t">БАЗИС-Т</Option>
                        <Option value={1} key="bazis_2006m">БАЗИС 2006М</Option>
                    </Select>
                </FormItemUSPDStyled>

              {/* MASSFLOW */}
              <FormItemUSPDStyled 
                    label="Расходомер" 
                    style={{ display: `${openMassflowSelectList ? '' : 'none'}`}}
                >
                    <Select 
                        labelInValue 
                        onChange={(massflow) => {
                            setDeviceData({ ...deviceData, ['id_massflow' as keyof IDevice]: massflow.value })
                        }}
                    >
                        {massflowData && Object.keys(massflowData).length > 0 
                         && massflowData.map((massFlowEl) => {
                            return (
                                <Option 
                                    name={massFlowEl.id_massflow}
                                    key={massFlowEl.id_massflow}
                                >
                                    {massFlowEl.serial_number}
                                </Option>
                            )
                        })}
                    </Select>
                </FormItemUSPDStyled>

                {/* SERIAL NUMBER */}
                <FormItemStyled label="Серийный номер" name="serial_number" >
                    <Input
                        autoComplete="off"
                        type="text"
                        name="serial_number" 
                        placeholder='0750... / 1011...'
                        onChange={changeDeviceDataFromInput}
                    />
                </FormItemStyled>

                {/* MARK */}
                <FormItemStyled label="Модель" name="mark">
                    <Input
                        autoComplete="off"
                        type="text"
                        name="mark"
                        placeholder='83F0... / ПМП-2... / ДТС1...'
                        onChange={changeDeviceDataFromInput}
                    />
                </FormItemStyled>
                
                {/* END DATE */}
                <FormItemStyledEndDate 
                    label="Дата окончания поверки" 
                    name="end_date"
                >
                    {/* <SpaceDatePickerStyled> */}
                        {/* <DatePickerStyled 
                            value={
                                deviceData.end_date
                                && !deviceData.end_date.includes('0000' || '00') ? 
                                    moment(customDateFormatToISO(deviceData.end_date) as string) : null
                            }
                            format='DD.MM.YYYY'
                            onChange={changeDeviceEndDate}
                            style={{ width: '100%' }}
                        /> */}
                         <Input
                            autoComplete="off"
                            type="text"
                            name="end_date" 
                            placeholder='01.01.2021'
                            maxLength={10}
                            onChange={changeDeviceDataFromInput}
                            style={
                                !dateValidate(deviceData.end_date)
                                    ? {border: '1px red solid', boxShadow: '0 0 0 2px rgb(255 0 0 / 20%)'} 
                                    : {}
                            }
                        />
                        <Button 
                            type={deviceData.end_date && deviceData.end_date.includes('0000' || '00') ? 'primary' : 'dashed'}
                            loading={loading} 
                            onClick={clearDeviceEndDate}
                        >
                            00.00.0000
                        </Button>
                    {/* </SpaceDatePickerStyled> */}
                </FormItemStyledEndDate>
                
                {/* MODATL FOOTER */}
                <FormItemFooterStyled style={{margin: 0}}>

                    {/* ОЧИСТИТЬ ПОЛЯ */}
                    <Popconfirm
                        title="Очистить?"
                        onConfirm={() => {
                            clearFields()
                            message.success('Очищено!')
                        }}
                        onCancel={() => {}}
                        okText="Да"
                        cancelText="Нет"
                        icon={<ClearOutlined style={{ color: 'red' }} />}
                    >
                        <Button 
                            danger
                            htmlType="submit"
                            loading={loading} 
                            style={{ marginRight: '20px'}}
                        >
                            <span style={{display: window.screen.width > 991 ? '' : 'none'}}>Очистить поля</span>
                            <ClearOutlined style={{margin: window.screen.width > 991 ? '' : '0'}}/>
                        </Button>
                    </Popconfirm>

                    {/* СОЗДАТЬ */}
                    <Popconfirm
                        title="Добавить новое устройство?"
                        onConfirm={() => {
                            dateValidate(deviceData.end_date) ? createNewDevice() : message.error('Невалидная дата окончания поверки')
                        }}
                        onCancel={() => {}}
                        okText="Да"
                        cancelText="Нет"
                        icon={<PlusOutlined style={{ color: '#1890ff'}}/>}
                    >
                        <Button 
                            type="primary" 
                            ghost
                            htmlType="submit"
                            loading={loading} 
                            style={{ marginRight: '20px'}}
                        >
                            Создать
                        </Button>
                    </Popconfirm>

                    {/* ОТМЕНА */}
                    <Button 
                        key="back" 
                        onClick={() => {
                            props.closeCreateDeviceModal()
                            clearFields()
                        }}
                    >
                        Отмена
                    </Button>
                </FormItemFooterStyled>
                
            </Form>
        </ModalStyled>
    )
}

const ModalStyled = styled(Modal)`
    max-width: 600px;
    
    @media (max-width: 575px) {
        max-width: 350px;
    }

    @media (max-width: 350px) {
        max-width: 310px;
    }
`

const FormItemUSPDStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // верхний input - selector ставим рядом, если выбран Расходомер
    .ant-form-item-control-input-content {
        display: flex;
    }

    button {
        font-size: 13px;
        padding: 4px 12px;
    }
    
    // обертка для input-ов
    > .ant-form-item-control {
        max-width: 75%;

        input {
            max-width: 23%;
        }
    }
`

const CustomLabelStyled = styled.p`
    display: flex;
    align-items: center;
    margin: 0 6px;
`

const FormItemSelectStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // верхний input - selector ставим рядом, если выбран Расходомер
    .ant-form-item-control-input-content {
        display: flex;
    }
    
    // обертка для input-ов
    > .ant-form-item-control {
        max-width: 75%;
    }
`

const FormItemStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;
    
    // редактируем поле input
    > .ant-form-item-control {
        max-width: 75%;
    }
`

const FormItemFooterStyled = styled(Form.Item)`

    // расположение кнопок в нижней части окна
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
    }
`
const FormItemStyledEndDate = styled(Form.Item)`

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;

        > input {
            margin-right: 10px;
        }
    }
`
// const SpaceDatePickerStyled = styled(Space)`
//     display: flex;

//     /* поле выбора даты растягиваем на все доступное пространство */
//     .ant-space-item:first-child {
//         width: 100%;
//     }
// `

const DatePickerStyled = styled(DatePicker)`
    /* убираем крестик для сброса даты, он тут без надобности */
    .ant-picker-clear {
        display: none;
    }
`

export default DeviceCreate
