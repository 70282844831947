import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
    children?: ReactNode
    style?: {}
    props?: {}
    onClick?: () => void
}

const Button: React.FC<IProps> = ({children, style, onClick, ...props}) => {

    if(children) {
        return (
            <ButtonStyle
                style={style}
                onClick={onClick}
                {...props}
            >
                {children}
            </ButtonStyle>
        )
    } else {
        return (
            <></>
        )
    }
}

const ButtonStyle = styled.button`
    margin: 2px 5px;
    cursor: pointer;

    :hover {
        background-color: #fff;
    }
`

export { Button }