import React, { useState, useEffect, useCallback } from 'react'
import { IDevice, IMassflow, IMassflowType } from '@interfaces/IDevices'
import config from '@config/config'
import { Button, Modal, Input, Form, message, Select, Popconfirm, DatePicker, Space } from 'antd'
import type { DatePickerProps } from 'antd';
import moment from 'moment';
import { ExclamationOutlined, SyncOutlined } from '@ant-design/icons'
import { customDateFormatToISO } from '@utils/DateParser';
import { dateValidate } from '@utils/validation'


import $api from '@http/$api'
import styled from 'styled-components'

const { Option } = Select

interface ICDProps {
    deviceData: IDevice
    visible: boolean
    refetch: () => void
    closeUpdateDeliteDeviceModal: () => void
    showUpdateDeliteDeviceModal: () => void
}

const DeviceUpdateDelete: React.FC<ICDProps> = (props) => {

    const [form] = Form.useForm();

    const [deviceData, setDeviceData] = useState({} as IDevice)
    const [loading, setLoading] = useState(false)
    const [massflowData, setMassflowData] = useState([] as IMassflow[])
    const [massflowTypeList, setMassflowTypeList] = useState([] as IMassflowType[])
    const [deviceMassflowId, setDeviceMassflowId] = useState('')
    const [openMassflowSelectList, setOpenMassflowSelectList] = useState(false)


    // подставляем данные из input
    const changeDeviceDataFromInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceData({ ...deviceData, [event.target.name]: event.target.value })
    }

    // const changeDeviceEndDate: DatePickerProps['onChange'] = (date, dateString) => {
    //     dateString && setDeviceData({ ...deviceData, end_date: dateString})
    // }

    const clearDeviceEndDate = () => {
        form.resetFields(['end_date']) 
        setDeviceData({ ...deviceData, end_date: '00.00.0000'})
    }


    // запрещаем набор некоторых символов в input type=number
    const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) => {
        ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()
    }

    // Запрашиваем список типов Расходомеров
    const getMassflowTypes =  useCallback( async () => {
        // начало загрузки любых данных с сервера
        setLoading(true)

        await $api.get(`${config.serverURL}/device/getmftypes`)
            .then((response) => {
                setLoading(false)
                setMassflowTypeList(response.data)

                // сравниваем все типы расходомеров с тем, который указан для устрйоства
                // если есть совпадения, фиксируем его ID, указанный в базе
                // этот ID необходим для обновления Расходомера
                for(let el of response.data) {
                    el.type.includes(deviceData.type_massflow) && setDeviceMassflowId(el.id_type)
                }
            })
            .catch((err) => {
                setLoading(false)
                message.error(err.response.data.err)
                throw new Error(err.response.data.err)
            })
    }, [deviceData.type_massflow])

    // Запрашиваем список расходомеров по определенному USPD
    const getAvailableMassflowList = async () => {
        // сбрасываем данные из select SERIAL NUMBER
        form.resetFields(['id_massflow']) 

        // начало загрузки любых данных с сервера
        setLoading(true)

        await $api.get(`${config.serverURL}/device/dts/getmf?uspd=` + deviceData.uspd_number)
            .then((response) => {
                if(response.data.length > 0) {
                    setMassflowData(response.data)
                    setLoading(false)
                    setOpenMassflowSelectList(true)
                    message.success(`Доступно расходомеров: ${response.data.length}`)
                } else {
                    setLoading(false)
                    setOpenMassflowSelectList(false)
                    message.error(`Для УСПД № ${deviceData.uspd_number} отсутствуют доступные расходомеры!`)
                }
            })
            .catch((err) => {
                setLoading(false)
                setOpenMassflowSelectList(false)
                message.error(`Для УСПД № ${deviceData.uspd_number} отсутствуют доступные расходомеры!`)
                message.error(err.response.data.err)
                throw new Error(err.response.data.err)
            })
    }

    //  Отправляем на сервер данные для создания нового устрйоства
    const updateDevice = async () => {
        // в соответствии с типом выбранного устрйоства формируем путь к этому устройству на сервере
        const typeDevice = deviceData.type_device === 0 ? 'complex' : deviceData.type_device === 1 ? 'mf/update' : deviceData.type_device === 2 ? 'dts/update' : 'level/update'

        // загрузка началась
        setLoading(true)

        await $api.post(`${config.serverURL}/device/${typeDevice}`, {
            id_dts: +deviceData.id, // тупо порядковый номер в каждой таблице отдельно level(1 - 26) dts(1 - 296) MF(1 - 618) update/delete
            id_level: +deviceData.id, // тупо порядковый номер в каждой таблице отдельно level(1 - 26) dts(1 - 296) MF(1 - 618) update/delete
            id_massflow: typeDevice === 'mf/update' ? +deviceData.id : +deviceData.id_massflow, // если выбрали расходомер, id_massflow тут будет = deviceData.id
            id_complex: +deviceData.id_complex, // если выбрали расходомер, id_massflow тут будет = deviceData.id
            uspd_number: deviceData.uspd_number, // порядковый номер УСПД в таблице complex: uspd_number = complex (1 - 234)  insert/update
            type_massflow: deviceMassflowId.toString(), // Тип расходомера требуется для таблицы расходомеров insert/update
            mark_device: deviceData.mark, // mark для расходомера insert/update
            mark_level: deviceData.mark, // mark для уровнеметра insert/update
            mark_dts: deviceData.mark, // mark датчика термосопротивления insert/update
            serial_number: deviceData.serial_number, // серийный номер insert/update
            end_date: deviceData.end_date, // датаокончания поверки insert/update
            company: deviceData.company // только для УСПД
        }).catch((err) => {
            setLoading(false)
            message.error('Не удалось обновить!')
            message.error(err.response.data.err)
            throw new Error(err.response.data.err)
        })

        // загрузка звершилась удачно
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Обновление прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // закрываем окошко обновления клиента
        props.closeUpdateDeliteDeviceModal()
    }

    const deleteDevice = async () => {
        // в соответствии с типом выбранного устрйоства формируем путь к этому устройству на сервере
        const typeDevice = deviceData.type_device === 1 ? 'mf' : deviceData.type_device === 2 ? 'dts' : 'level'

        // загрузка началась
        setLoading(true)

        await $api.post(`${config.serverURL}/device/${typeDevice}/delete`, {
            id_dts: +deviceData.id, // тупо порядковый номер в каждой таблице отдельно level(1 - 26) dts(1 - 296) MF(1 - 618) update/delete
            id_level: +deviceData.id, // тупо порядковый номер в каждой таблице отдельно level(1 - 26) dts(1 - 296) MF(1 - 618) update/delete
            id_massflow: +deviceData.id, // если выбрали расходомер, id_massflow тут будет = deviceData.id
        }).catch((err) => {
            setLoading(false)
            message.error('Удаление не удалось!')
            message.error(err.response.data.err)
            throw new Error(err.response.data.err)
        })

        // загрузка звершилась удачно
        setLoading(false)


        // выводим сообщение об успешном обновлении
        message.success('Удалено!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // закрываем окошко обновления клиента
        props.closeUpdateDeliteDeviceModal()
    }

    // если данные поступили и окно редактирования открыто, подставляем данные в поля ввода
    useEffect(() => {
        !props.visible ? setDeviceData({} as IDevice) : setDeviceData(props.deviceData)
    }, [props.deviceData, props.visible])

    // раскрываем/скрываем разные поля формы в зависимости от типа устройства
    useEffect(() => {
        // если меняется TYPE DEVICE скрываем поле SERIAL NUMBER для ДТС
        if(deviceData.type_device !== 2) setOpenMassflowSelectList(false) 

        // если TYPE DEVICE === Расходомер запрашиваем доступные типы расходомеров
        if(deviceData.type_device === 1) {
          getMassflowTypes()
        } 
    }, [deviceData.type_device, getMassflowTypes])

    return (
        <ModalStyled
            mask={false}
            visible={props.visible}
            title="Обновить устройство"
            onCancel={props.closeUpdateDeliteDeviceModal}
            footer={null}
        >
            <Form form={form}>

                {/* TYPE DEVICE */}
                <FormItemSelectStyled label="Тип устройства">
                    <Select 
                        value={deviceData.type_device} 
                        onChange={(typeDevice) => {
                            setDeviceData({ ...deviceData, ['type_device' as keyof IDevice]: typeDevice })
                        }}
                        disabled
                    >
                        <Option value={0} key="uspd">УСПД</Option>
                        <Option value={1} key="mf">Расходомер</Option>
                        <Option value={2} key="dts">ДТС</Option>
                        <Option value={3} key="level">Уровнемер</Option>
                    </Select>
                    <CustomLabelStyled style={{ display: `${deviceData.type_device === 1 ? '' : 'none'}`}} >Тип:</CustomLabelStyled>
                    <Select 
                        style={{ display: `${deviceData.type_device === 1 ? '' : 'none'}`}} 
                        onChange={(typeMassflow) => {
                            setDeviceData({ ...deviceData, ['type_massflow' as keyof IDevice]: typeMassflow.value })
                            setDeviceMassflowId(typeMassflow.key)
                        }}
                        value={{ value: deviceData.type_massflow, key: deviceMassflowId }}
                        labelInValue
                    >
                        {massflowTypeList.map((massflowType) => {
                            return (
                                <Option 
                                    value={massflowType.type}
                                    key={massflowType.id_type}
                                >
                                    {massflowType.type}
                                </Option>
                            )
                        })}
                    </Select>
                </FormItemSelectStyled>


                {/* COMPANY */}
                <FormItemUSPDStyled 
                    label="Компания" style={{display: deviceData.type_device === 0 ? '' : 'none'}}
                >
                    <Input
                        autoComplete="off"
                        type="text"
                        name="company"
                        placeholder='ООО "Наименование Компании"'
                        onChange={changeDeviceDataFromInput}
                        value={deviceData.company}
                    />
                </FormItemUSPDStyled>


                {/* USPD */}
                <FormItemUSPDStyled 
                    label="УСПД" style={{display: deviceData.type_device === 0 ? 'none' : ''}}
                >
                    <Input
                        autoComplete="off"
                        type="number"
                        name="uspd_number"
                        placeholder='123'
                        onChange={changeDeviceDataFromInput}
                        onKeyDown={blockInvalidChar}
                        value={deviceData.uspd_number}
                        disabled
                    />
                    <Button 
                        disabled={deviceData.type_device !== 2}
                        type="primary" 
                        htmlType="submit"
                        loading={loading} 
                        onClick={getAvailableMassflowList}
                        style={{display: `${deviceData.type_device !== 2 ? 'none' : ''}`}}
                    >
                        Проверить
                    </Button>
                </FormItemUSPDStyled>

                {/* MASSFLOW */}
                <FormItemUSPDStyled 
                    label="Расходомер" 
                    style={{ display: `${!openMassflowSelectList || deviceData.type_device === 0 ? 'none' : ''}`}}
                >
                    <Select 
                        labelInValue 
                        onChange={(massflow) => {
                            setDeviceData({ ...deviceData, ['id_massflow' as keyof IDevice]: massflow.value })
                        }}
                    >
                        {massflowData && Object.keys(massflowData).length > 0 
                         && massflowData.map((massFlowEl) => {
                            return (
                                <Option 
                                    key={massFlowEl.id_massflow}
                                >
                                    {massFlowEl.serial_number}
                                </Option>
                            )
                        })}
                    </Select>
                </FormItemUSPDStyled>

                {/* SERIAL NUMBER */}
                <FormItemStyled 
                    label="Серийный номер" 
                    style={{display: deviceData.type_device === 0 ? 'none' : ''}}
                >
                    <Input
                        autoComplete="off"
                        type="text"
                        name="serial_number" 
                        placeholder='0750... / 1011...'
                        onChange={changeDeviceDataFromInput}
                        value={deviceData.serial_number}
                    />
                </FormItemStyled>

                {/* MARK */}
                <FormItemStyled
                    label="Модель" 
                    style={{display: deviceData.type_device === 0 ? 'none' : ''}}
                >
                    <Input
                        autoComplete="off"
                        type="text"
                        name="mark"
                        placeholder='83F0... / ПМП-2... / ДТС1...'
                        onChange={changeDeviceDataFromInput}
                        value={deviceData.mark}
                    />
                </FormItemStyled>
                
                {/* END DATE */}
                <FormItemStyledEndDate 
                    label="Дата окончания поверки" 
                    name="end_date"
                >
                    {/* <SpaceDatePickerStyled> */}
                        {/* <DatePickerStyled 
                            value={
                                deviceData.end_date
                                && !deviceData.end_date.includes('0000' || '00') ? 
                                    moment(customDateFormatToISO(deviceData.end_date) as string) : null
                            }
                            format='DD.MM.YYYY'
                            onChange={changeDeviceEndDate}
                            style={{ width: '100%' }}
                        /> */}

                        <Input
                            autoComplete="off"
                            type="text"
                            name="end_date" 
                            placeholder='01.01.2021'
                            maxLength={10}
                            onChange={changeDeviceDataFromInput}
                            value={!deviceData?.end_date?.includes('00') ? deviceData.end_date : ''}
                            style={
                                !dateValidate(deviceData.end_date)
                                    ? {border: '1px red solid', boxShadow: '0 0 0 2px rgb(255 0 0 / 20%)'} 
                                    : {}
                            }
                        />
                        <Button
                            type={deviceData.end_date && deviceData.end_date.includes('0000' || '00') ? 'primary' : 'dashed'}
                            loading={loading} 
                            onClick={clearDeviceEndDate}
                        >
                            00.00.0000
                        </Button>
                    {/* </SpaceDatePickerStyled> */}
                </FormItemStyledEndDate>
                
                {/* MODATL FOOTER */}
                <FormItemFooterStyled style={{margin: 0}}>
                    <Popconfirm
                        title="Желаете удалить данное устройство?"
                        onConfirm={deleteDevice}
                        onCancel={() => {message.info('Удаление отменено!')}}
                        okText="Да"
                        cancelText="Нет"
                        icon={<ExclamationOutlined style={{ color: 'red' }} />}
                    >
                        <Button 
                            className="del-btn footer-btn"
                            danger
                            htmlType="submit"
                            loading={loading} 
                            style={{display: deviceData.type_device === 0 ? 'none' : ''}}
                        >
                            Удалить
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Желаете обновить?"
                        onConfirm={() => {
                            dateValidate(deviceData.end_date) ? updateDevice() : message.error('Невалидная дата окончания поверки')
                        }}
                        onCancel={() => {message.info('Обновление отменено!')}}
                        okText="Да"
                        cancelText="Нет"
                        icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                    >
                        <Button 
                            type="primary" 
                            ghost
                            htmlType="submit"
                            className="update-btn footer-btn"
                            loading={loading} 
                        >
                            Обновить
                        </Button>
                    </Popconfirm>
                    <Button 
                        key="back" 
                        onClick={() => {
                            props.closeUpdateDeliteDeviceModal()
                            setLoading(false)
                        }}
                    >
                        Отмена
                    </Button>
                </FormItemFooterStyled>
                
            </Form>
        </ModalStyled>
    )
}

const ModalStyled = styled(Modal)`
    max-width: 600px;
    
    @media (max-width: 575px) {
        max-width: 350px;
    }

    @media (max-width: 350px) {
        max-width: 310px;
    }
`

const FormItemUSPDStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // верхний input - selector ставим рядом, если выбран Расходомер
    .ant-form-item-control-input-content {
        display: flex;
    }
    
    // обертка для input-ов
    > .ant-form-item-control {
        max-width: 75%;
    }
`

const CustomLabelStyled = styled.p`
    display: flex;
    align-items: center;
    margin: 0 6px;
`

const FormItemSelectStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // верхний input - selector ставим рядом, если выбран Расходомер
    .ant-form-item-control-input-content {
        display: flex;
    }
    
    // обертка для input-ов
    > .ant-form-item-control {
        max-width: 75%;
    }
`

const FormItemStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;
    
    // редактируем поле input
    > .ant-form-item-control {
        max-width: 75%;
    }
`

const FormItemFooterStyled = styled(Form.Item)`

    // расположение кнопок в нижней части окна
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;

        .update-btn {
            margin: 0 20px;

            @media (max-width: 350px) {
                margin: 0 1px;
            }
        }

        .del-btn {
            margin-right: auto;

            @media (max-width: 350px) {
                margin: 0;
            }
        }

    }
`

const FormItemStyledEndDate = styled(Form.Item)`

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;

        > input {
            margin-right: 10px;
        }
    }
`

const SpaceDatePickerStyled = styled(Space)`
    display: flex;

    /* поле выбора даты растягиваем на все доступное пространство */
    .ant-space-item:first-child {
        width: 100%;
    }
`

const DatePickerStyled = styled(DatePicker)`
    /* убираем крестик для сброса даты, он тут без надобности */
    .ant-picker-clear {
        display: none;
    }
`

export default DeviceUpdateDelete
