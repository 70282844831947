import React, { useState, useEffect, useCallback } from 'react'
// interfaces
import { IAccessMonitoringTankNew, IAccessMonitoring } from '@interfaces/IAccessMonitoring'
// global config
import config from '@config/config'
// ANTD
import { Button, Modal, Input, Form, message, Select, Popconfirm, DatePicker, Space, Switch } from 'antd'
import { ExclamationOutlined, SyncOutlined } from '@ant-design/icons'
// validator
import { imeiValidate } from '@utils/validation'
// Axios
import $api from '@http/$api'
// style
import styled from 'styled-components'


const { Option } = Select

interface IProps {
    carier: IAccessMonitoring
    visible: boolean
    refetch: () => void
    closeTankCreateModal: () => void
}

const TankCreate: React.FC<IProps> = (props) => {

    const [newTank, setNewTank] = useState({upgraded: false, tank_type: 0} as IAccessMonitoringTankNew)
    const [loading, setLoading] = useState(false)
    const [invalidField, setInvalidField] = useState<string>('')

    const [form] = Form.useForm();

    const changeNewTankData = (event:React.ChangeEvent<HTMLInputElement>) => {
        setInvalidField('')
        setNewTank({...newTank, [event.target.name]: event.target.value})
    }

    const changeNewTankUpgradedData = (checked:boolean) => {
        setInvalidField('')
        setNewTank({...newTank, upgraded: checked})
    }

    const changeTankType = (value: number) => {
        setInvalidField('')
        setNewTank({...newTank, tank_type: value})
    }

    // очистка стейта и полей ввода
    const clearFields = () => {
        setLoading(false)
        form.resetFields() 
        setNewTank({} as IAccessMonitoringTankNew)
    }


    const createTank = async () => {

        if(!newTank.name) {
            setInvalidField('name')
            return message.error('Заполните название!')
        }
        if(!newTank.vpn_client) {
            setInvalidField('vpn_client')
            return message.error('Заполните VPN')
        }
        if(!newTank.ip) {
            setInvalidField('ip')
            return message.error('Заполните IP')
        }
        if(!newTank.imei) {
            setInvalidField('imei')
            return message.error('Заполните imei')
        }
        if(!imeiValidate(newTank.imei)) {
            setInvalidField('imei')
            return message.error('Поле imei должно содержать от 15 до 16 цмфр')
        }
        if(!newTank.uspd_number) {
            setInvalidField('uspd_number')
            return message.error('Заполните УСПД')
        }
        if(!newTank.time_zone) {
            setInvalidField('time_zone')
            return message.error('Заполните временную зону')
        }

        // отправка началась
        setLoading(true)

        await $api.post('/monitoring/addTank', {
            carrier_id: +props.carier.carrier_id,
            name: newTank.name,
            client_reg_id: newTank.client_reg_id,
            vpn_client: +newTank.vpn_client,
            ip: newTank.ip,
            imei: newTank.imei,
            peer_address: +newTank.peer_address,
            uspd_number: +newTank.uspd_number,
            time_zone: +newTank.time_zone,
            tank_type: +newTank.tank_type,
            upgraded: !!newTank.upgraded,
            numberSim: newTank.numberSim
        }).catch((error) => {
            // отправка звершилась не удачно
            setLoading(false)
            message.error('Не удалось создать!')
            message.error(error.response.data.err)
            throw new Error(error.response.data.err)
        })

        // отправка звершилась удачно
        setLoading(false)

        // очищаем форму, если создание прошло успешно
        
        // выводим сообщение об успешном обновлении
        message.success('Создание прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // закрываем окошко обновления клиента
        props.closeTankCreateModal()
        
        // сбрасываем значения из полей ввода
        clearFields()
    }


    return (
        <Modal
            mask={false}
            visible={props.visible}
            title={`Добавить машину для: ${props.carier.cname}`}
            onCancel={props.closeTankCreateModal}
            footer={
                <Popconfirm
                    title="Создать?"
                    onConfirm={() => {
                        newTank ? createTank() : message.error('Неверный формат даты')
                    }}
                    onCancel={() => {message.info('Создание отменено!')}}
                    okText="Да"
                    cancelText="Нет"
                    icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                >
                    <Button
                        type="primary" 
                        ghost
                        htmlType="submit"
                        className="update-btn footer-btn"
                        loading={loading} 
                    >
                        Создать
                    </Button>
                </Popconfirm>
            }
        >
            <Form form={form}>
                {/* NAME */}
                <FormItem label="Гос. № машины" name="name" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <Input
                        autoComplete="off"
                        type="text"
                        name="name"
                        placeholder='AC 1234 56 RUS'
                        onChange={changeNewTankData}
                        value={newTank.name}
                        status={invalidField === 'name' ? 'error' : ''}
                    />
                </FormItem >

                {/* client_reg_id */}
                <FormItem label="ФСРАР ID">
                    <Input
                        autoComplete="off"
                        type="text"
                        name="client_reg_id"
                        placeholder=''
                        onChange={changeNewTankData}
                        value={newTank.client_reg_id}
                    />
                </FormItem >
            
                {/* vpn_client */}
                <FormItem label="VPN" name="vpn_client" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <Input
                        autoComplete="off"
                        type="text"
                        name="vpn_client"
                        placeholder='563'
                        onChange={changeNewTankData}
                        value={newTank.vpn_client}
                        status={invalidField === 'vpn_client' ? 'error' : ''}
                    />
                </FormItem >
            
                {/* ip */}
                <FormItem label="IP" name="ip" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <Input
                        autoComplete="off"
                        type="text"
                        name="ip"
                        placeholder='1.1.1.1'
                        onChange={changeNewTankData}
                        value={newTank.ip}
                        status={invalidField === 'ip' ? 'error' : ''}
                    />
                </FormItem >
            
                {/* imei от 15 до 16 символов  */}
                <FormItem label="imei" name="imei" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <Input
                        autoComplete="off"
                        type="text"
                        name="imei"
                        placeholder=''
                        onChange={changeNewTankData}
                        value={newTank.imei}
                        status={invalidField === 'imei' ? 'error' : ''}
                    />
                </FormItem >
            
                {/* uspd_number */}
                <FormItem label="УСПД" name="uspd_number" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <Input
                        autoComplete="off"
                        type="text"
                        name="uspd_number"
                        placeholder='123'
                        onChange={changeNewTankData}
                        value={newTank.uspd_number}
                        status={invalidField === 'uspd_number' ? 'error' : ''}
                    />
                </FormItem >

                {/* time_zone */}
                <FormItem label="Временная зона" name="time_zone" rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}>
                    <Input
                        autoComplete="off"
                        type="text"
                        name="time_zone"
                        placeholder='3'
                        onChange={changeNewTankData}
                        value={newTank.time_zone}
                        status={invalidField === 'time_zone' ? 'error' : ''}
                    />
                </FormItem >
            
                {/* tank_type */}
                <FormItem label="Тип цистерны">
                    <Select defaultValue={newTank.tank_type} style={{ width: 120 }} onChange={changeTankType}>
                        <Option value={0}>Контейнер</Option>
                        <Option value={1}>Цистерна(без расходомера)</Option>
                        <Option value={2}>Цистерна</Option>
                    </Select>
                </FormItem >

                {/* numberSim - не обязательно проверку */}
                <FormItem label="SIM номер" name="numberSim">
                    <Input
                        autoComplete="off"
                        type="text"
                        name="numberSim"
                        placeholder=''
                        onChange={changeNewTankData}
                        value={newTank.numberSim}
                    />
                </FormItem >
            
                {/* upgraded */}
                <FormItem label="Отслеживание">
                    <Switch
                        onChange={changeNewTankUpgradedData}
                        defaultChecked={newTank.upgraded}
                        checked={newTank.upgraded}
                    />
                </FormItem >
            </Form>
           
        </Modal>
    )
}

const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 76%;
    }

    .ant-select {
        width: 100% !important;
    }
`


export default TankCreate
