import React, { useState, useEffect, useCallback, useContext } from 'react'
// Use Query
import { useQuery } from 'react-query'
// clients AXIOS methods
import clientsAPI from '@api/ClientsAPI'
// Default settings
import config from '@config/config'
// Interfaces
import { IClientUspd } from '@interfaces/IClients'
import { IClientsColumnListShow } from '@interfaces/IColumnsListShow'
// Context
import { UserContext } from '@context/UserContext'
// ANTD
import { Table, Button, Popover, message, Spin } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import {
    InfoCircleOutlined,
    CommentOutlined,
    SettingOutlined,
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    WifiOutlined,
    QuestionOutlined,
    PlusOutlined,
    ClearOutlined
} from '@ant-design/icons'
// Components
import FilterColumnInput from '@components/UI/FilterColumn-Input'
import FilterColumnSelect from '@components/UI/FilterColumn-Select'
import AdditionalInfo from '@components/ClientList/AdditionalInfo'
import ClientUpdate from '@components/ClientList/ClientUpdate'
import ClientCreate from '@components/ClientList/ClientCreate'
import ColumnsListSettingsModal from '@components/ClientList/ClientsColumnListEdit'
// Styled
import styled from 'styled-components'


const UspdList: React.FC = () => {

    // контекст тут нужен для блокировки функционала по добавлению/редактированию/удалению клиентов по роли
    const auth = useContext(UserContext)

    // AXIOS get Clients list + useQuery
    const { isLoading, data, refetch } = useQuery<IClientUspd[], Error>(
        'uspd',
        async () => {
            return await clientsAPI.getUspd()
        },
        {
            ...config.useQueryClientsDataConfig,
            notifyOnChangeProps: ['data'], // ререндер происходит только если изменились полученные данные
            onSuccess: (res) => {
                filtration(res)
            },
            onError: (err) => {
                message.error('Ошибка подключения к серверу')
            },
        },
    )


    // отфильтрованная таблица
    const [filteredData, setFilteredData] = useState([] as IClientUspd[])
    // параметры фильтрации таблицы
    const filterListFromLocalStorage = JSON.parse(localStorage.getItem('uspdFilters') as string) as IClientUspd
    const [filterParams, setFilterParams] = useState(filterListFromLocalStorage || {})
    // true - если нажали очистить формы фильтрации
    const [clearInputFieldSending, setClearInputField] = useState(false)


    // модальные окна клиента (создание/редактирование)
    // показать/скрыть окна редактирования/создания клиента
    const [visibleUpdateClientModal, setVisibleUpdateClientModal] = useState(false)
    const [visibleCreateClientModal, setVisibleCreateClientModal] = useState(false)
    // показать/скрыть окно настроек колонок
    const [visibleColumnsSettings, setVisibleColumnsSettings] = useState(false)
    // данные в модальном окне изменения клиента
    const [modalClientData, setModalClientData] = useState({} as IClientUspd)


    // стандартное состояние колонок: скрыть/показать     
    const defaultColumnList = {
        showName: true,
        showInfo: true,
        showUSPD: true,
        showServiceStatus: true,
        showCity: true,
        showIP: true,
        showVPN: true,
        showOS: true,
        showMonitoringStatus: true,
        showStatus: true,
        showMonitoringParamsStatus: true,
        showSSL: true
    } as IClientsColumnListShow
    const columnListFromLocalStorage = JSON.parse(localStorage.getItem('uspdColumnList') as string) as IClientsColumnListShow
    const [columnList, setColumnList] = useState(columnListFromLocalStorage ?? defaultColumnList)

    // изменяем состояние колонок таблицы: скрыть/показать
    const changeColumnsShow = (modifiedListOfColumns: {} ) => {
        setColumnList({...columnList, ...modifiedListOfColumns})
        localStorage.setItem('uspdColumnList', JSON.stringify(modifiedListOfColumns ?? columnList))
    }

    // фильтрация с использованием INPUT
    const inputTextFiltering = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterParams({ ...filterParams, [event.target.name]: event.target.value })
    }

    // фильтрация через выпадающее меню
    const dropDownListFilteringService = (value: string | number | boolean) => {
        setFilterParams({...filterParams, ['service_status' as string]: value })
    }
    const dropDownListFilteringOS = (value: string | number | boolean) => {
        setFilterParams({...filterParams, ['os' as string]: value})
    }
    const dropDownListFilteringStatus = (value: string | number | boolean) => {
        setFilterParams({...filterParams, ['status' as string]: value})
    }
    const dropDownListFilteringMonitoring = (value: string | number | boolean) => {
        setFilterParams({...filterParams, ['monitoring_status' as string]: value})
    }
    const dropDownListFilteringSending = (value: string | number | boolean) => {
        setFilterParams({...filterParams, ['monitoring_params_status' as string]: value})
    }

    // очищаем фильтр
    const clearFilterParams = () => {
        setFilterParams({} as IClientUspd)
        setClearInputField(!clearInputFieldSending)
        message.success('Фильтры очищены!')
    }


    // фильтрация данных
    const filtration = useCallback((arrData: IClientUspd[]): void => {
        
        if ( filterParams && arrData && Object.keys(filterParams).length > 0 && Object.keys(arrData).length > 0) {
            // фильтрация началась
            const newData =
                filterParams &&
                arrData &&
                arrData.filter((row) => {
                    // для каждой строки (row)
                    let check
                    for (let elKey in filterParams) {

                        // если сравнение идет по monitoring_params_status(поле: Sending)
                        // и в фильтре указано значение отличающееся от 0
                        // то подойдут все поля, которые отличаются от 0
                        if (elKey === 'monitoring_params_status' && +filterParams[`${elKey as keyof IClientUspd}`] !== 0) {
                            check = row[`${elKey as keyof IClientUspd}`] !== 0
                            // если check = false, пропускаем
                            if (!check) break
                        }    

                        // отдельная фильтрация по полю USPD на полное соответствие введенным данным
                        if(elKey === 'number_uspd') {
                            check = filterParams['number_uspd'].toString().toLowerCase() 
                            === row['number_uspd'].toString().toLowerCase() 
                            || filterParams['number_uspd'].toString().toLowerCase() 
                            === ''
                            
                            // если check = false хотя бы по 1 параметру, то пропускаем
                            if (!check) break
                        }

                        // для всех остальных значений
                        // сравниваем каждое значение row.elKey с filterParams.elKey
                        // P.S.: сравниваем row.name, row.company, row.uspd, ... c filterParams.name, filterParams.company, filterParams.uspd, ...
                        check = row[`${elKey as keyof IClientUspd}`]
                            .toString()
                            .toLowerCase()
                            .includes(filterParams[`${elKey as keyof IClientUspd}`].toString().toLowerCase())
                        // если check = false хотя бы по 1 параметру, то пропускаем
                        if (!check) break
                    }
                    return check
                })
                setFilteredData(newData)
        } else {
            setFilteredData(arrData)
        }
    }, [filterParams])


    // если страницу перезагрузили или просто сменили вкладку,
    // то при возвращении обратно отобразим фильтры, которые ранее записали в localStorage
    useEffect(() => {
        setFilterParams(JSON.parse(localStorage.getItem('uspdFilters') as string))
    }, [])


    // Если изменяются параметры фильтра
    useEffect(() => {
        localStorage.setItem('uspdFilters', JSON.stringify(filterParams))
        // фильтруем данные 
        filtration(data as IClientUspd[])
    }, [filterParams, data, filtration])


    // колонки для таблицы
    const columns: ColumnsType<IClientUspd> = [

        // ROW NUMBER
        // window.screen.width > 991 ? {
        //     title: '№',
        //     key: 'index',
        //     fixed: 'left',
        //     align: 'center',
        //     width:  80,
        //     render: (value, record, index) => <>{index}</>

        // } : {
        //     width: 0
        // },

        // если убраны все колонки:
        Object.values(columnList).every((showElement) => {
            return showElement === false
        }) ? {
            fixed: 'left',
        } : {
            width: 0,
            fixed: 'left',
        },

        // NAME
        columnList.showName ? {
            title: (
                <FilterColumnInput 
                    name="name" 
                    title="Компания" 
                    onChange={inputTextFiltering} 
                    key="name" 
                    clientType="uspd" 
                clearInputField={clearInputFieldSending}
                />
            ),
            dataIndex: 'name',
            key: 'name',
            fixed: window.screen.width > 991 ? 'left' : false,
            align: 'center',
        } : {
            width: 0,
        },

        // (?) INFO
        columnList.showInfo ? {
            title: <QuestionOutlined />,
            width: 50,
            render: (record: IClientUspd) => (
                <>
                    <Popover title="Версии ПО:" content={<AdditionalInfo client={record} clientType="uspd" />}>
                        <InfoCircleOutlined style={{ color: 'green' }} />
                    </Popover>
                    { record.comment && 
                        <Popover title="Comment" content={<p>{record.comment}</p>}>
                            <CommentOutlined style={{ color: 'gray' }} />
                        </Popover>
                    } 
                </>
            ),
            fixed: window.screen.width > 991 ? 'left' : false,
            align: 'center',
        } : {
            width: 0,
        },

        // USPD
        columnList.showUSPD ? {
            title: (
                <FilterColumnInput 
                    name="number_uspd" 
                    title="УСПД" 
                    onChange={inputTextFiltering} 
                    key="number_uspd" 
                    clientType="uspd"
                    clearInputField={clearInputFieldSending}
                />
            ),
            dataIndex: 'number_uspd',
            key: 'number_uspd',
            fixed: window.screen.width > 991 ? 'left' : false,
            sorter: (a, b) => a.number_uspd - b.number_uspd,
            align: 'center',
            width: 120,
        } : {
            width: 0,
        },

        // SERVICE
        columnList.showServiceStatus ? {
            title: (
                <FilterColumnSelect
                    name="service_status"
                    title="Сервис"
                    onChange={dropDownListFilteringService}
                    key="service_status"
                    iconType="check"
                    values={['', 'true', 'false']}
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            render: (record: IClientUspd) =>
                record.service_status ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                ),
            key: 'service_status',
            align: 'center',
            width: 160,
        } : {
            width: 0
        },

        // CITY
        columnList.showCity ? {
            title: (
                <FilterColumnInput 
                    name="city" 
                    title="Город" 
                    onChange={inputTextFiltering} 
                    key="city" 
                    clientType="uspd"
                    clearInputField={clearInputFieldSending}
                />
            ),
            dataIndex: 'city',
            key: 'city',
            align: 'center',
        } : {
            width: 0
        },

        // IP
        columnList.showIP ? {
            title: (
                <FilterColumnInput 
                    name="ip" 
                    title="IP" 
                    onChange={inputTextFiltering} 
                    key="ip" 
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            dataIndex: 'ip',
            key: 'ip',
            align: 'center',
        } : {
            width: 0
        },

        // VPN
        columnList.showVPN ? {
            title: (
                <FilterColumnInput 
                    name="vpn_client" 
                    title="VPN" 
                    onChange={inputTextFiltering} 
                    key="vpn_client"  
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            dataIndex: 'vpn_client',
            key: 'vpn_client',
            align: 'center',
            defaultSortOrder: 'descend',
            sorter: (a, b) => +a.vpn_client - +b.vpn_client,
            width: 140
        } : {
            width: 0
        },

        // OS
        columnList.showOS ? {
            title: (
                <FilterColumnSelect
                    name="os"
                    title="ОС"
                    onChange={dropDownListFilteringOS}
                    key="os"
                    iconType="defaultIcon"
                    values={['', '8', '9']}
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            dataIndex: 'os',
            key: 'os',
            align: 'center',
            width: 120
        } : {
            width: 0
        },

        // STATUS
        columnList.showStatus ? {
            title: (
                <FilterColumnSelect
                    name="status"
                    title="Статус"
                    onChange={dropDownListFilteringStatus}
                    key="status"
                    iconType="wifi"
                    values={['', '1', '0']}
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            key: 'status',
            render: (record: IClientUspd) =>
                record.status ? <WifiOutlined style={{ color: 'green' }} /> : <WifiOutlined style={{ color: 'red' }} />,
            align: 'center',
            width: 120,
        } : {
            width: 0
        },

        // MONITORING
        columnList.showMonitoringStatus ? {
            title: (
                <FilterColumnSelect
                    name="monitoring_status"
                    title="Мониторинг"
                    onChange={dropDownListFilteringMonitoring}
                    key="monitoring_status"
                    iconType="check"
                    values={['', '0', '1']}
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            render: (record: IClientUspd) =>
                record.monitoring_status ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                ),
            key: 'monitoring_status',
            align: 'center',
            width: 120
        } : {
            width: 0
        },

        // SENDING
        columnList.showMonitoringParamsStatus ? {
            title: (
                <FilterColumnSelect
                    name="monitoring_params_status"
                    title="Рассылка"
                    onChange={dropDownListFilteringSending}
                    key="monitoring_params_status"
                    iconType="check"
                    values={['', '1', '0']}
                    clearInputField={clearInputFieldSending}
                    clientType="uspd"
                />
            ),
            render: (record: IClientUspd) =>
                !record.monitoring_params_status ? (
                    <CloseOutlined style={{ color: 'red' }} />
                ) : (
                    <CheckOutlined style={{ color: 'green' }} />
                ),
            key: 'monitoring_params_status',
            align: 'center',
            width: 120,
            // dataIndex: 'monitoring_params_status',
        } : {
            width: 0
        },

        // RT DATE
        columnList.showSSL ? {
            title: (
                <FilterColumnInput 
                    name="rt_date" 
                    title="РуТокен" 
                    onChange={inputTextFiltering} 
                    key="rt_date" 
                    clientType="uspd"
                    clearInputField={clearInputFieldSending}
                />
            ),
            key: 'rt_date',
            align: 'center',
            render: (record: IClientUspd) => <p style={{fontWeight: 'bold', margin: 0}}>{record.rt_date}</p>
        } : {
            width: 0
        },
            
        // EDIT ROW
        {
            title: (
                <Popover placement="topLeft" content={'Скрыть/показать колонки'}>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<SettingOutlined />}
                        onClick={() => {
                            setVisibleColumnsSettings(true)
                        }}
                    />
                </Popover>
            ),
            width: 70,
            render: (record: IClientUspd) => {
                return (
                    <Popover placement="topLeft" content={'Изменить'}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            disabled={auth.role === '1' || auth.role === '3' || auth.role === ''}
                            onClick={() => {
                                setModalClientData(record)
                                setVisibleUpdateClientModal(true)
                            }}
                        />
                    </Popover>
                )
            },
            align: 'center',
            fixed: window.screen.width > 991 ? 'right' : false,
            key: 'editClient'
        },   
    ]

    return (
        <div>
            {isLoading ? (
                <SpinnerStyle>
                    <Spin size="large" />
                </SpinnerStyle>
            ) : (
                <>
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        bordered
                        rowKey='id'
                        pagination={{ 
                            position: ['bottomRight'], 
                            pageSizeOptions: [10, 20, 50, 100, 150, 200], 
                            defaultPageSize: 10
                        }}
                        scroll={{ x: 1500, y: window.screen.width > 991 ? 'calc(100vh - 295px)' : 'calc(100vh - 400px)' }}
                        style={{ borderBottom: '1px solid #ccc' }}
                    />
                    <SettingsPanel>
                        <Button
                            type="primary"
                            onClick={clearFilterParams}
                        > 
                            Очистить фильтры <ClearOutlined/>
                        </Button>
                        <Button
                            type="primary"
                            disabled={auth.role === '1' || auth.role === '3' || auth.role === ''}
                            onClick={() => {
                                setVisibleCreateClientModal(true)
                            }}
                        > 
                            Создать <PlusOutlined/>
                        </Button>
                    </SettingsPanel>
                </>
            )}
            <ClientUpdate
                clientData={modalClientData}
                visible={visibleUpdateClientModal}
                refetch={() => refetch()}
                closeUpdateClientModal={() => setVisibleUpdateClientModal(false)}
                showUpdateClientModal={() => setVisibleUpdateClientModal(true)}
            />
            <ClientCreate
                visible={visibleCreateClientModal}
                refetch={() => refetch()}
                clientType='uspd'
                closeCreateClientModal={() => setVisibleCreateClientModal(false)}
                showCreateClientModal={() => setVisibleCreateClientModal(true)}
            />
            <ColumnsListSettingsModal
                columnList={columnList}
                visible={visibleColumnsSettings}
                clientType='uspd'
                onChange={changeColumnsShow}
                closeColumnsSettingsModal={() => setVisibleColumnsSettings(false)}
                showColumnsSettingsModal={() => setVisibleColumnsSettings(true)}
            />
        </div>
    )
}

const SettingsPanel = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    padding: 8px 1% 0;

    > button {
        margin: 0 0 0 20px;
    }
`

const SpinnerStyle = styled.div`
    height: calc(100vh - 144px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export default UspdList
