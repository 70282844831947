import React, { useState, useEffect, useCallback } from 'react'
import { IAccessMonitoringUserList, IAccessMonitoring, IAccessMonitoringUser} from '@interfaces/IAccessMonitoring'
import config from '@config/config'
import { Button, Modal, Input, Form, message, Select, Popconfirm, DatePicker, Space } from 'antd'
import { ExclamationOutlined, SyncOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { standardDateValidate } from '@utils/validation'
import $api from '@http/$api'
import styled from 'styled-components'
import { useQuery } from 'react-query'



const { Option } = Select

interface IProps {
    record: IAccessMonitoringUser
    closeUserUpdateModal: () => void
    visible: boolean
    refetch: () => void
}

const UserUpdate: React.FC<IProps> = ({ record, visible, closeUserUpdateModal, refetch }) => {

    const [userData, setUserData] = useState<IAccessMonitoringUser>(record)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setUserData(record)
    }, [record])


    const changeUserState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({...userData, [event.target.name]: event.target.value})
    }

    const updateUserDate = async () => {
        // отправка началась
        setLoading(true)

        await $api.post('/monitoring/users/update', {
            user_id: userData.user_id,
            login: userData.login,
            password_cipher: userData.pass,
            pay_date: userData.pay_date,
        }).catch((error) => {
            // отправка завершилась не удачно
            setLoading(false)
            message.error('Не удалось обновить!')
            message.error(error.response.data.err)
            throw new Error(error.response.data.err)
        })

        // отправка звершилась удачно
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Обновление прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        refetch()

        // закрываем окошко обновления клиента
        closeUserUpdateModal()
    }
   
    return (
        <Modal
            mask={false}
            visible={visible}
            title={`Редактирование: ${record.login}`}
            onCancel={closeUserUpdateModal}
            width={700}
            style={{zIndex: '999999'}}
            footer={
                <Popconfirm
                    title="Желаете обновить?"
                    onConfirm={() => {
                        standardDateValidate(userData.pay_date) ? updateUserDate() : message.error('Неверный формат даты')
                    }}
                    onCancel={() => {message.info('Обновление отменено!')}}
                    okText="Да"
                    cancelText="Нет"
                    icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                >
                    <Button
                        type="primary" 
                        ghost
                        htmlType="submit"
                        className="update-btn footer-btn"
                        loading={loading} 
                    >
                        Обновить
                    </Button>
                </Popconfirm>
            }
        >
            {/* login */}
            <FormItem label="Логин">
                <Input
                    autoComplete="off"
                    type="text"
                    placeholder='Логин'
                    name="login"
                    onChange={changeUserState}
                    value={userData.login}
                />
            </FormItem >

            {/* pay_date */}
            <FormItem label="Дата оплаты">
                <Input
                    autoComplete="off"
                    type="text"
                    placeholder='2022-12-12'
                    name="pay_date"
                    onChange={changeUserState}
                    value={userData.pay_date}
                />
            </FormItem >

            {/* pass */}
            <FormItem label="Пароль">
                <Input.Password 
                    disabled
                    autoComplete="off"
                    type="text"
                    placeholder='Временно недоступно'
                    name="pass"
                    onChange={changeUserState}
                    value={userData.pass}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </FormItem >
           
        </Modal>     
    )
}

const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 76%;
    }
`


export default UserUpdate
