import React, {useContext} from 'react'
import { Layout } from 'antd'
import Table from '@components/AccessMonitoring/Table'
import '@styles/clientsList.css'
import { UserContext } from '@context/UserContext'


const AccessMonitoring: React.FC = () => {

    const auth = useContext(UserContext)

    return (
        <Layout
            hidden={auth.role !== '2' ? true : false}
        >
            <Layout.Content>
                <Table />
            </Layout.Content>
        </Layout>
    )
}


export default AccessMonitoring
