import React, { useState, useEffect } from 'react'
import { IClient } from '@interfaces/IClients'
import config from '@config/config'
import { Button, Modal, Input, Form, Switch, message, Popconfirm } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import $api from '@http/$api'
import styled from 'styled-components'

interface IUCProps {
    clientData: IClient
    visible: boolean
    refetch: () => void
    closeUpdateClientModal: () => void
    showUpdateClientModal: () => void
}

const ClientUpdate: React.FC<IUCProps> = (props) => {
    const [clientData, setClientData] = useState({} as IClient)
    const [loading, setLoading] = useState(false)

    // при отрисовке получаемые данные запихиваем в локальный стэйт
    useEffect(() => {
        setClientData(props.clientData)
    }, [props.clientData])

    // если окно скрыто, сбрасываем его сожержимое до дефолтных прараметров клиента
    useEffect(() => {
        !props.visible && setClientData(props.clientData)
    }, [props.visible, props.clientData])

    // подставляем данные из input
    const changeClientData = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientData({ ...clientData, [event.target.name]: event.target.value })
    }

    // подставляем данные из переключателей
    const changeVisible = () => {
        setClientData({ ...clientData, visible: !clientData.visible })
    }
    const changeServiceStatus = () => {
        setClientData({ ...clientData, service_status: !clientData.service_status })
    }

    //  тут отправим данные на сервер
    const sendNewClientData = async () => {
        // загрузка началась
        setLoading(true)
        await $api.post(`${config.serverURL}/list/updateclient`, {
            type: clientData.type.toString() === 'uspd' ? '1' : clientData.type.toString() === 'mobile' ? '2' : '3',
            ip: clientData.ip.toString(),
            vpn_client: +clientData.vpn_client,
            name: clientData.name.toString(),
            company: clientData.company.toString(),
            city: clientData.city.toString(),
            number_uspd: +clientData.number_uspd,
            iccid: clientData.iccid.toString(),
            number: clientData.number.toString(),
            visible: !!clientData.visible,
            service_status: !!clientData.service_status,
            id: +clientData.id,
        }).catch((err) => {
            setLoading(false)
            message.error('Не удалось обновить!')
            message.error(err.response.data.err)
            throw new Error(err.response.data.err)
        })
        // загрузка звершилась удачно
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Обновление прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // закрываем окошко обновления клиента
        props.closeUpdateClientModal()
    }
       
        // запрещаем набор некоторых символов в input type=number
        const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) => {
            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()
        }

    return (
        <Modal
            mask={false}
            visible={props.visible}
            title="Окно редактирования"
            onCancel={props.closeUpdateClientModal}
            footer={[
                // UPDATE
                <Popconfirm
                    title="Желаете обновить?"
                    onConfirm={sendNewClientData}
                    onCancel={() => {message.info('Обновление отменено!')}}
                    okText="Да"
                    cancelText="Нет"
                    key="update"
                    icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                >
                    <Button 
                        style={{marginRight: '10px'}} 
                        key="submit" 
                        type="primary" 
                        loading={loading} 
                    >
                        Обновить
                    </Button>
                </Popconfirm>,
                
                // CANCEL
                <Button key="back" onClick={props.closeUpdateClientModal}>
                    Отмена
                </Button>,
            ]}
        >
            <Form initialValues={clientData}>
                <FormItemStyled label="Наименование" >
                    <Input
                        autoComplete="off"
                        type="text"
                        placeholder=''
                        name="name"
                        onChange={changeClientData}
                        value={clientData.name}
                    />
                </FormItemStyled>
                <FormItemStyled label="USPD">
                    <Input
                        autoComplete="off"
                        type="number"
                        placeholder="123"
                        name="number_uspd"
                        onChange={changeClientData}
                        onKeyDown={blockInvalidChar}
                        value={clientData.number_uspd}
                    />
                </FormItemStyled>
                <FormItemStyled label="Расположение">
                    <Input
                        autoComplete="off"
                        type="text"
                        name="city"placeholder="Moscow"
                        onChange={changeClientData}
                        value={clientData.city}
                    />
                </FormItemStyled>
                <FormItemStyled label="IP">
                    <Input
                        autoComplete="off"
                        type="text"
                        placeholder='1.1.1.1'
                        name="ip"
                        onChange={changeClientData}
                        value={clientData.ip}
                    />
                </FormItemStyled>
                <FormItemStyled label="VPN">
                    <Input
                        autoComplete="off"
                        type="number"
                        placeholder='123'
                        name="vpn_client"
                        onKeyDown={blockInvalidChar}
                        onChange={changeClientData}
                        value={clientData.vpn_client}
                    />
                </FormItemStyled>
                <FormItemStyled label="Компания">
                    <Input
                        autoComplete="off"
                        type="text"
                        name="company"
                        placeholder='ООО "Company Name"'
                        onChange={changeClientData}
                        value={clientData.company}
                    />
                </FormItemStyled>
                <FormItemStyled label="iccid">
                    <Input
                        autoComplete="off"
                        type="text"
                        name="iccid"
                        placeholder='897010261234567890'
                        onChange={changeClientData}
                        value={clientData.iccid}
                    />
                </FormItemStyled>
                <FormItemStyled label="Номер">
                    <Input
                        autoComplete="off"
                        type="text"
                        maxLength={12}
                        placeholder="+79123456789"
                        name="number"
                        onChange={changeClientData}
                    />
                </FormItemStyled>
                <FormItemStyled label="Сервис">
                    <Switch
                        checked={clientData.service_status}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={changeServiceStatus}
                    />
                </FormItemStyled>
                <FormItemStyled label="Видимость">
                    <Switch
                        checked={clientData.visible}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={changeVisible}
                    />
                </FormItemStyled>
            </Form>
        </Modal>
    )
}

const FormItemStyled = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 76%;
    }
`

export default ClientUpdate
