import React, { useState, useEffect, useCallback } from 'react'
import { IAccessMonitoringTank } from '@interfaces/IAccessMonitoring'
import { Button, Modal, Input, Form, message, Select, Popconfirm, DatePicker, Space } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { standardDateValidate } from '@utils/validation'
import $api from '@http/$api'


const { Option } = Select

interface IProps {
    tankData: IAccessMonitoringTank
    visible: boolean
    refetch: () => void
    closeTankUpdateModal: () => void
}

const TankUpdate: React.FC<IProps> = (props) => {

    const [date, setDate] = useState(props.tankData.pay_date)
    const [loading, setLoading] = useState(false)

    const changeDate = (event:React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value)
    }

    useEffect(() => {
        setDate(props.tankData.pay_date)
    }, [props.tankData])

    const updateTankDate = async () => {

        // отправка началась
        setLoading(true)

        await $api.post('/monitoring/updateTank', {
            tank_id: props.tankData.tank_id,
            name: props.tankData.name,
            pay_date: date
        }).catch((error) => {
            // отправка завершилась не удачно
            setLoading(false)
            message.error('Не удалось обновить!')
            message.error(error.response.data.err)
            throw new Error(error.response.data.err)
        })

        // отправка звершилась удачно
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Обновление прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // закрываем окошко обновления клиента
        props.closeTankUpdateModal()
    }

    return (
        <Modal
            mask={false}
            visible={props.visible}
            title={`Обновить дату для: ${props.tankData.name}`}
            onCancel={props.closeTankUpdateModal}
            footer={
                <Popconfirm
                    title="Желаете обновить?"
                    onConfirm={() => {
                        standardDateValidate(date) ? updateTankDate() : message.error('Неверный формат даты')
                    }}
                    onCancel={() => {message.info('Обновление отменено!')}}
                    okText="Да"
                    cancelText="Нет"
                    icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                >
                    <Button
                        type="primary" 
                        ghost
                        htmlType="submit"
                        className="update-btn footer-btn"
                        loading={loading} 
                    >
                        Обновить
                    </Button>
                </Popconfirm>
            }
        >
            {/* DATE */}
            <Form.Item label="Дата">
                <Input
                    autoComplete="off"
                    type="text"
                    placeholder='2022-12-12'
                    onChange={changeDate}
                    value={date}
                />
            </Form.Item >
           
        </Modal>
    )
}

export default TankUpdate
