import React, { useState, useEffect, useCallback, useContext } from 'react'
// Use Query
import { useQuery } from 'react-query'
// device AXIOS methods
import devicesAPI from '@api/DevicesAPI'
// Default settings
import config from '@config/config'
// Interfaces
import { IDevice } from '@interfaces/IDevices'
import { IDevicesColumnListShow } from '@interfaces/IColumnsListShow'
// Context
import { UserContext } from '@context/UserContext'
// ANTD
import { Table, Button, message, Popover, Spin } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import {
    SettingOutlined,
    EditOutlined,
    PlusOutlined,
    RetweetOutlined,
    ClearOutlined
} from '@ant-design/icons'
// dateParser
import { dateToDays } from '@utils/DateParser'
// Components
import FilterColumnInput from '@components/UI/FilterColumn-Input'
import FilterColumnSelect from '@components/UI/FilterColumn-Select'
import DeviceColumnListEdit from '@components/DevicesList/DeviceColumnListEdit'
import DeviceCreate from '@components/DevicesList/DeviceCreate'
import DeviceUpdateDelete from '@components/DevicesList/DeviceUpdate&Delete'
// styled
import styled from 'styled-components'


const DeviceList: React.FC = () => {

    // контекст тут нужен для блокировки функционала по добавлению/редактированию/удалению устрйоств по роли
    const auth = useContext(UserContext)

    // AXIOS get Clients list + useQuery
    const { isLoading, data, refetch } = useQuery<IDevice[], Error>(
        'device',
        async () => {
            return await devicesAPI.getDevices()
        },
        {
            ...config.useQueryDeviceDataConfig,
            notifyOnChangeProps: ['data', 'refetch'],
            onSuccess: (res) => {
                filtration(res)
            },
            onError: (err) => {
                message.error('Ошибка подключения к серверу')
            },
        },
    )

    // console.log('data', data)


    // отфильтрованная таблица
    const [filteredData, setFilteredData] = useState([] as IDevice[])
    // параметры фильтрации таблицы
    const filterListFromLocalStorage = JSON.parse(localStorage.getItem('deviceFilters') as string) as IDevice
    const [filterParams, setFilterParams] = useState(filterListFromLocalStorage || {} as IDevice)
    // фильтрация по цвету
    const [endDateColorFilterParam, setEndDateColorFilterParam] = useState(localStorage.getItem('endDateColor') || '')
    // true - если нажали очистить формы фильтрации
    const [clearInputField, setClearInputField] = useState(false)


    // модальные окна клиента (создание/редактирование)
    // показать/скрыть окна редактирования/создания клиента
    const [visibleUpdateDeleteDeviceModal, setVisibleUpdateDeleteDeviceModal] = useState(false)
    const [visibleCreateDeviceModal, setVisibleCreateDeviceModal] = useState(false)
    // показать/скрыть окно настроек колонок
    const [visibleColumnsSettings, setVisibleColumnsSettings] = useState(false)
    // данные в модальном окне изменения клиента
    const [modalUpdateDeleteDeviceData, setModalUpdateDeleteDeviceData] = useState({} as IDevice)


    // стандартное состояние колонок: скрыть/показать     
    const defaultColumnList = {
        showTypeDevice: true,
        showCompany: true,
        showUSPD: true,
        showMark: true,
        showSerialNumber: true,
        showEndDate: true
    } as IDevicesColumnListShow
    const columnListFromLocalStorage = JSON.parse(localStorage.getItem('deviceColumnList') as string) as IDevicesColumnListShow
    const [columnList, setColumnList] = useState(columnListFromLocalStorage ?? defaultColumnList)


    // изменяем состояние колонок таблицы: скрыть/показать
    const changeColumnsShow = (modifiedListOfColumns: {} ) => {
        setColumnList({...columnList, ...modifiedListOfColumns})
        localStorage.setItem('deviceColumnList', JSON.stringify(modifiedListOfColumns ?? columnList))
    }


    // фильтрация с использованием INPUT
    const inputTextFiltering = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterParams({ ...filterParams, [event.target.name]: event.target.value })
    }
    // фильтрация через выпадающее меню
    const typeDeviceSelectFiltering = (value: string | number | boolean) => {
        setFilterParams({ ...filterParams, ['type_device' as string]: value })
    }

    const colorEndDateSelectFiltering = (value: string | number | boolean) => {
        setEndDateColorFilterParam(value as string)
    }
   

    // очищаем фильтр
    const clearFilterParams = () => {
        setFilterParams({} as IDevice)
        setClearInputField(!clearInputField)
        setEndDateColorFilterParam('')
        message.success('Фильтры очищены!')
    }
    

    // фильтрация данных таблицы
    const filtration = useCallback((arrData: IDevice[]): void => {
        
        // Берем текущщую дату (ДНИ)
        const currentDate = Math.floor(Date.now() / 1000 / 60 / 60 / 24)
        
        if ( arrData && Object.keys(arrData).length > 0) {

            // фильтрация началась
            const newData = filterParams && arrData && arrData.filter((row) => {

                let check = true

                // переводим дату окончания поверки в кол-во ДНЕЙ прошедших с 1970г
                const deviceDate = dateToDays(row.end_date)
                
                if(filterParams) {
                    // для каждой строки (row)
                    for (let elKey in filterParams) {

                        // отдельная фильтрация по полю USPD на полное соответствие введенным данным
                        if(elKey === 'uspd_number') {
                            check = filterParams['uspd_number'].toString().toLowerCase() 
                            === row['uspd_number'].toString().toLowerCase() 
                            || filterParams['uspd_number'].toString().toLowerCase() 
                            === ''
                            
                            // если check = false хотя бы по 1 параметру, то пропускаем
                            if (!check) break
                        }

                        // сравниваем каждое значение row.elKey с filterParams.elKey
                        // P.S.: сравниваем row.name, row.company, row.uspd, ... c filterParams.name, filterParams.company, filterParams.uspd, ...
                        check = row[`${elKey as keyof IDevice}`]
                        .toString()
                        .toLowerCase()
                        .includes(filterParams[`${elKey as keyof IDevice}`].toString().toLowerCase())
                        // если check = false хотя бы по 1 параметру, то пропускаем
                        if (!check) break
                    }
                }

                // если выбран определенный цвет даны окончания поверки, то фильтруем дополнительно по цвету
                if (endDateColorFilterParam && check) {
                    // Проверяем соответствие цвету
                    if(endDateColorFilterParam === 'yellow') {
                        check = (deviceDate - currentDate) <= 45 && (deviceDate - currentDate) > 15
                    }

                    if(endDateColorFilterParam === 'orange') {
                        check = (deviceDate - currentDate) <= 15 && (deviceDate - currentDate) > 0
                    } 

                    if(endDateColorFilterParam === 'red') {
                        check = (deviceDate - currentDate) <= 0
                    } 

                    if(endDateColorFilterParam === '') {
                        check = true
                    }
                }
                return check
            })

            setFilteredData(newData)
        } else {
            setFilteredData(arrData)
        }
    }, [filterParams, endDateColorFilterParam])


    // Если изменяются параметры фильтра
    useEffect(() => {
        // запоминаем новые параметры фильтра
        localStorage.setItem('deviceFilters', JSON.stringify(filterParams))
        localStorage.setItem('endDateColor', endDateColorFilterParam)
        // отфильтровываем по новой
        filtration(data as IDevice[])
    }, [filterParams, endDateColorFilterParam, filtration, data])


    dateToDays('00.00.0000')

    // колонки для таблицы
    const columns: ColumnsType<IDevice> = [

        // ROW NUMBER
        // window.screen.width > 991 ? {
        //     title: '№',
        //     key: 'index',
        //     fixed: 'left',
        //     align: 'center',
        //     width:  80,
        //     render: (value, record, index) => <>{index}</>
        // } : {
        //     width: 0
        // },

        // если убраны все колонки:
        Object.values(columnList).every((showElement) => {
            return showElement === false
        }) ? {
            fixed: 'left',
        } : {
            width: 0,
            fixed: 'left',
        },

        // TYPE DEVICE
        columnList.showTypeDevice ? 
        {
            title: (
                <FilterColumnSelect
                    name="type_device"
                    title="Тип устройства"
                    onChange={typeDeviceSelectFiltering}
                    key="type_device"
                    iconType="text"
                    values={['', '0', '1', '2', '3']}
                    clearInputField={clearInputField}
                    clientType="device"
                />
            ),
            key: 'type_device',
            fixed: window.screen.width > 991 ? 'left' : false,
            align: 'center',
            sorter: (a, b) => a.type_device - b.type_device,
            render: (value, record: IDevice, index) => (
                <>
                    {`${record.type_device === 0 ? 'УСПД' : 
                            record.type_device === 1 ? 'Расходомер' : 
                                record.type_device === 2 ? 'ДТС' : 
                                    'Уровнемер'} 
                    ${record.type_massflow}`}
                </>
            )
            
        } : {
            width: 0,
            fixed: 'left',
        },
        
        // Company
        columnList.showCompany ? {
            title: (
                <FilterColumnInput 
                    name="company" 
                    title="Компания" 
                    onChange={inputTextFiltering} 
                    key="company" 
                    clientType="device"
                    clearInputField={clearInputField}
                />
            ),
            dataIndex: 'company',
            key: 'company',
            fixed: window.screen.width > 991 ? 'left' : false,
            align: 'center',
        } : {
            width: 0,
            fixed: 'left',
        },

        // USPD
        columnList.showUSPD ? {
            title: (
                <FilterColumnInput 
                    name="uspd_number" 
                    title="УСПД" 
                    onChange={inputTextFiltering} 
                    key="uspd_number" 
                    clientType="device"
                    clearInputField={clearInputField}
                />
            ),
            dataIndex: 'uspd_number',
            key: 'uspd_number',
            fixed: window.screen.width > 991 ? 'left' : false,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.uspd_number - b.uspd_number,
            align: 'center',
            width: 150,

        } : {
            width: 0,
            fixed: 'left',
        },

        // SERIAL NUMBER
        columnList.showSerialNumber ? {
            title: (
                <FilterColumnInput 
                    name="serial_number" 
                    title="Серийный номер" 
                    onChange={inputTextFiltering} 
                    key="serial_number" 
                    clientType="device"
                    clearInputField={clearInputField}
                />
            ),
            key: 'serial_number',
            render: (record: IDevice) =>
                record.serial_number ? record.serial_number : '-',
            align: 'center',
        } : {
            width: 0
        },

        // MARK
        columnList.showMark ? {
            title: (
                <FilterColumnInput 
                    name="mark" 
                    title="Модель" 
                    onChange={inputTextFiltering} 
                    key="mark" 
                    clientType="device"
                    clearInputField={clearInputField}
                />
            ),
            render: (record: IDevice) =>
            record.mark ? record.mark : '-',
            key: 'mark',
            align: 'center',
            // dataIndex: 'mark',
            // width: 150
        } : {
            width: 0
        },

        // END DATE
        columnList.showEndDate ? {
            title: (
                <>
                    <h1 style={{fontSize: '14px', margin: 0}}>Дата окончания поверки</h1>
                    <EndDateStyled>
                        <FilterColumnInput 
                            name="end_date" 
                            title="" 
                            onChange={inputTextFiltering} 
                            key="end_date_input" 
                            clientType="device"
                            clearInputField={clearInputField}
                        />
                        <FilterColumnSelect
                            name="end_date"
                            title=""
                            onChange={colorEndDateSelectFiltering}
                            key="end_date_select"
                            iconType="color"
                            values={['', 'yellow', 'orange', 'red']}
                            clearInputField={clearInputField}
                            clientType="device"
                        />
                    </EndDateStyled>
                </>
            ),
            render: (record: IDevice) => {

                // переводим в милисекунды дату окончания поверки (ДНИ)
                const deviceDate = dateToDays(record.end_date)

                // Берем текущщую дату (ДНИ)
                const currentDate = Math.floor(Date.now() / 1000 / 60 / 60 / 24)

                // Если стоит некорректная дата "00.00.0000" или пустая строка ""
                if(record.end_date.includes('0000') || !record.end_date) {
                    return (
                        <EndDateElStyled style={{ color: config.deviceEndDataColor.incorrect}}><p>{record.end_date}</p></EndDateElStyled>
                    )
                }

                // если до окончания поверки осталось от 45 дней до 16 окрашиваем ЖЕЛТЫМ
                if((deviceDate - currentDate) <= 45 && (deviceDate - currentDate) > 15) {
                    return (
                        <EndDateElStyled style={{ color: config.deviceEndDataColor.yellow}}><p>{record.end_date}</p></EndDateElStyled>
                    )
                }

                // если до окончания поверки осталось от 15 дней до 0 окрашиваем ОРАНЖЕВЫМ
                if((deviceDate - currentDate) <= 15 && (deviceDate - currentDate) > 0) {
                    return (
                        <EndDateElStyled style={{ color: config.deviceEndDataColor.orange}}><p>{record.end_date}</p></EndDateElStyled>
                    )
                }

                // если поверка закончилась окрашиваем КРАСНЫМ
                if((deviceDate - currentDate) <= 0) {
                    return (
                        <EndDateElStyled style={{ color: config.deviceEndDataColor.red}}><p>{record.end_date}</p></EndDateElStyled>
                    )
                }

                // в остальных случаях оставляем как есть
                return (
                    <EndDateElStyled style={{ color: config.deviceEndDataColor.normal}}><p>{record.end_date}</p></EndDateElStyled>
                )
            },
            className: 'endDateColumn',
            key: 'end_date',
            align: 'center',
        } : {
            width: 0
        },
            
        // EDIT ROW
        {
            title: (
                    <Popover placement="topLeft" content={'Скрыть/показать колонки'}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<SettingOutlined />}
                            onClick={() => {
                                setVisibleColumnsSettings(true)
                            }}
                        />
                    </Popover>
            ),
            width: 70,
            render: (record: IDevice) => 
                (
                    <Popover placement="topLeft" content={'Изменить'}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            disabled={auth.role === '0' || auth.role === '3' || auth.role === ''}
                            onClick={() => {
                                setModalUpdateDeleteDeviceData(record)
                                setVisibleUpdateDeleteDeviceModal(true)
                            }}
                        /> 
                    </Popover>
                ),
            align: 'center',
            fixed: window.screen.width > 991 ? 'right' : false,
            key: 'editDevice',
        },   
    ]

    return (
        <div>
            {isLoading ? (
                <SpinnerStyle>
                    <Spin size="large" />
                </SpinnerStyle>
            ) : (
                <div>
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        bordered
                        rowKey= {(record) => {
                            return (
                                `${record.id}` 
                                + `${record.id_complex}`
                                + `${record.id_massflow}`
                                + `${record.uspd_number}`
                                + `${record.type_device}`
                            )
                        }}
                        pagination={{ 
                            position: ['bottomRight'], 
                            pageSizeOptions: [10, 20, 50, 100, 150, 200], 
                            defaultPageSize: 10
                        }}
                        scroll={{ x: 1500, y: window.screen.width > 991 ? 'calc(100vh - 255px)' : 'calc(100vh - 360px)'}}
                        style={{ borderBottom: '1px solid #ccc' }}
                    />
                    <SettingsPanel>
                        <Button
                            type="primary"
                            onClick={clearFilterParams}
                        > 
                            {window.screen.width > 450 ? 'Очистить фильтры' : ''} <ClearOutlined/>
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { 
                                try {
                                    refetch() 
                                    message.success('Данные обновлены!')
                                } catch(error) {
                                    message.error(`${error}`)
                                }
                            }}
                        > 
                            {window.screen.width > 450 ? 'Обновить' : ''} <RetweetOutlined/>
                        </Button>
                        <Button
                            type="primary"
                            disabled={auth.role === '0' || auth.role === '3' || auth.role === ''}
                            onClick={() => {
                                setVisibleCreateDeviceModal(true)
                            }}
                        > 
                            {window.screen.width > 450 ? 'Создать' : ''} <PlusOutlined/>
                        </Button>
                    </SettingsPanel>
                </div>
            )}
            <DeviceUpdateDelete
                deviceData={modalUpdateDeleteDeviceData}
                visible={visibleUpdateDeleteDeviceModal}
                refetch={() => refetch()}
                closeUpdateDeliteDeviceModal={() => setVisibleUpdateDeleteDeviceModal(false)}
                showUpdateDeliteDeviceModal={() => setVisibleUpdateDeleteDeviceModal(true)}
            />
            <DeviceCreate
                visible={visibleCreateDeviceModal}
                refetch={() => refetch()}
                closeCreateDeviceModal={() => setVisibleCreateDeviceModal(false)}
                showCreateDeviceModal={() => setVisibleCreateDeviceModal(true)}
            />
            <DeviceColumnListEdit
                columnList={columnList}
                visible={visibleColumnsSettings}
                onChange={changeColumnsShow}
                closeColumnsSettingsModal={() => setVisibleColumnsSettings(false)}
                showColumnsSettingsModal={() => setVisibleColumnsSettings(true)}
            />
        </div>
    )
}



// нижняя панель инструментов таблицы (создать, обновить данные, очистить фильтры)
const SettingsPanel = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    padding: 8px 1% 0;

    @media (max-width: 450px) {
        span {
            margin: 0 !important;
        }
    }

    > button {
        margin: 0 0 0 20px;
    }
`

// стили для заголовка колонки "Дата окончания поверки"
const EndDateStyled = styled.div`
    width: 100%;
    display: flex;

    > div {
        flex: 1;

        form {
            margin: 0 5px;
        }
    }
`

// стили каждой строки даты окончания поверки
const EndDateElStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;

    > p {
        margin: 0;
    }
`

// стили спинера-заглушки, если данные не прогрузились с сервера
const SpinnerStyle = styled.div`
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export default DeviceList
