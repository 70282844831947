import React, { useState, useEffect, useCallback } from 'react'
// interfaces
import { IAccessMonitoringCompanyNew,  } from '@interfaces/IAccessMonitoring'
// global config
import config from '@config/config'
// Axios
import $api from '@http/$api'
// RegExpValidator
import { stringNameValidate } from '@utils/validation'
// style
import styled from 'styled-components'
// ANTD
import { Button, Modal, Input, Form, message, Popconfirm } from 'antd'
import { SyncOutlined } from '@ant-design/icons'


interface IProps {
    visible: boolean
    refetch: () => void
    closeCompanyCreateModal: () => void
}

const CompanyCreate: React.FC<IProps> = (props) => {

    const [newCompany, setNewCompany] = useState({} as IAccessMonitoringCompanyNew)
    const [loading, setLoading] = useState(false)
    const [invalidField, setInvalidField] = useState<string>('')

    const changeNewCompanyData = (event:React.ChangeEvent<HTMLInputElement>) => {
        setNewCompany({...newCompany, [event.target.name]: event.target.value})
    }

    const createCompany = async () => {

        if(!newCompany.name || !stringNameValidate(newCompany.name)) {
            setInvalidField('name')
            return message.error('Некорректное название!')
        }

        // отправка началась
        setLoading(true)

        await $api.post('/monitoring/addCompany', {
            carrier_reg_id: newCompany.carrier_reg_id,
            name: newCompany.name,
            full_name: newCompany.full_name,
            short_name: newCompany.short_name,
            inn: newCompany.inn,
            kpp: newCompany.kpp,
            country: newCompany.country,
            region_code: newCompany.region_code,
            description: newCompany.description,
            mru_name: newCompany.mru_name
        }).catch((error) => {
            // отправка звершилась не удачно
            setLoading(false)
            message.error('Не удалось создать!')
            message.error(error.response.data.err)
            throw new Error(error.response.data.err)
        })

        // отправка звершилась удачно
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Создание прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        setNewCompany({} as IAccessMonitoringCompanyNew)

        // закрываем окошко обновления клиента
        props.closeCompanyCreateModal()
    }

    return (
        <Modal
            mask={false}
            visible={props.visible}
            title={`Добавить организацию`}
            onCancel={props.closeCompanyCreateModal}
            footer={
                <Popconfirm
                    title="Создать?"
                    onConfirm={() => {
                        newCompany ? createCompany() : message.error('Неверный формат даты')
                    }}
                    onCancel={() => {message.info('Создание отменено!')}}
                    okText="Да"
                    cancelText="Нет"
                    icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                >
                    <Button
                        type="primary" 
                        ghost
                        htmlType="submit"
                        className="update-btn footer-btn"
                        loading={loading} 
                    >
                        Создать
                    </Button>
                </Popconfirm>
            }
        >
            {/* carrier_reg_id */}
            <FormItem label="ФС РАР ID">
                <Input
                    autoComplete="off"
                    type="text"
                    name="carrier_reg_id"
                    placeholder=''
                    onChange={changeNewCompanyData}
                    value={newCompany.carrier_reg_id}
                />
            </FormItem >

            {/* name - обязательное */}
            <FormItem label="Название" required={true}>
                <Input
                    autoComplete="off"
                    type="text"
                    name="name"
                    placeholder='ОАО "Компания"'
                    onChange={(event) => {
                        changeNewCompanyData(event)
                        setInvalidField('')
                    }}
                    status={invalidField === 'name' ? 'error' : ''}
                    value={newCompany.name}
                />
            </FormItem >
           
            {/* full_name */}
            <FormItem label="Полное название">
                <Input
                    autoComplete="off"
                    type="text"
                    name="full_name"
                    placeholder=''
                    onChange={changeNewCompanyData}
                    value={newCompany.full_name}
                />
            </FormItem >
           
            {/* short_name */}
            <FormItem label="Краткое название">
                <Input
                    autoComplete="off"
                    type="text"
                    name="short_name"
                    placeholder=''
                    onChange={changeNewCompanyData}
                    value={newCompany.short_name}
                />
            </FormItem >
        
            {/* inn */}
            <FormItem label="ИНН">
                <Input
                    autoComplete="off"
                    type="text"
                    name="inn"
                    placeholder='044525225'
                    onChange={changeNewCompanyData}
                    value={newCompany.inn}
                />
            </FormItem >
        
            {/* kpp */}
            <FormItem label="КПП">
                <Input
                    autoComplete="off"
                    type="text"
                    name="kpp"
                    placeholder=''
                    onChange={changeNewCompanyData}
                    value={newCompany.kpp}
                />
            </FormItem >

            {/* country */}
            <FormItem label="Страна">
                <Input
                    autoComplete="off"
                    type="text"
                    name="country"
                    placeholder='RU'
                    onChange={changeNewCompanyData}
                    value={newCompany.country}
                />
            </FormItem >

            {/* region_code */}
            <FormItem label="Код региона">
                <Input
                    autoComplete="off"
                    type="text"
                    name="region_code"
                    placeholder='64'
                    onChange={changeNewCompanyData}
                    value={newCompany.region_code}
                />
            </FormItem >
           
            {/* description */}
            <FormItem label="Описание">
                <Input
                    autoComplete="off"
                    type="text"
                    name="description"
                    placeholder=''
                    onChange={changeNewCompanyData}
                    value={newCompany.description}
                />
            </FormItem >
           
            {/* mru_name */}
            <FormItem label="МРУ РАР">
                <Input
                    autoComplete="off"
                    type="text"
                    name="mru_name"
                    placeholder=''
                    onChange={changeNewCompanyData}
                    value={newCompany.mru_name}
                />
            </FormItem >
           
        </Modal>
    )
}

const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 76%;
    }
`

export default CompanyCreate
