import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'

interface IProps {
    children?: ReactNode | undefined
    mask?: boolean | undefined
    visible?: boolean | undefined
    title?: ReactNode | undefined
    onCancel?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
    width?: string | number | undefined
    style?: React.CSSProperties | undefined
    footer?: React.ReactNode | undefined
}

function CustomModal(props: IProps) {
    return (
        <ModalStyle
            mask={props.mask}
            visible={props.visible}
            title={props.title}
            onCancel={props.onCancel}
            width={props.width}
            style={props.style}
            footer={props.footer}
        >
            {props.children}
        </ModalStyle>
    )
}

const ModalStyle = styled(Modal)`
    z-index: 100;
`

export { CustomModal }
