import React, { useState, useEffect } from 'react'
import { IClientsColumnListShow } from '@interfaces/IColumnsListShow'
import { Modal, Form, Switch, Button } from 'antd'
import styled from 'styled-components'

interface IUCProps {
    columnList: IClientsColumnListShow
    visible: boolean
    clientType: string
    onChange: (obj: IClientsColumnListShow) => void
    closeColumnsSettingsModal: () => void
    showColumnsSettingsModal: () => void
}

const ColumnsListSettingsModal: React.FC<IUCProps> = (props) => {

    // состояние кпопок
    const [columnList, setColumnList] = useState(props.columnList)
    
    // название открытой и закрытой кнопок
    const openLable = 'Open'
    const closeLable = 'Close'

    // если изменяется состояние кнопки, прокидываем это сразу в таблицу
    useEffect(() => {
        props.onChange(columnList)
    }, [columnList])
    
    // для кадого переключателя своя функция изменения общего состояния
    const showCompanyChange = () => {
        setColumnList({...columnList, showCompany: !columnList.showCompany})
    }
    const showNameChange = () => {
        setColumnList({...columnList, showName: !columnList.showName})
    }
    const showInfoChange = () => {
        setColumnList({...columnList, showInfo: !columnList.showInfo})
    }
    const showUSPDChange = () => {
        setColumnList({...columnList, showUSPD: !columnList.showUSPD})
    }
    const showServiceStatusChange = () => {
        setColumnList({...columnList, showServiceStatus: !columnList.showServiceStatus})
    }
    const showCityChange = () => {
        setColumnList({...columnList, showCity: !columnList.showCity})
    }
    const showIPChange = () => {
        setColumnList({...columnList, showIP: !columnList.showIP})
    }
    const showVPNChange = () => {
        setColumnList({...columnList, showVPN: !columnList.showVPN})
    }
    const showOSChange = () => {
        setColumnList({...columnList, showOS: !columnList.showOS})
    }
    const showMonitoringStatusChange = () => {
        setColumnList({...columnList, showMonitoringStatus: !columnList.showMonitoringStatus})
    }
    const showMonitoringParamsStatusChange = () => {
        setColumnList({...columnList, showMonitoringParamsStatus: !columnList.showMonitoringParamsStatus})
    }
    const showStatusChange = () => {
        setColumnList({...columnList, showStatus: !columnList.showStatus})
    }
    const showSSLChange = () => {
        setColumnList({...columnList, showSSL: !columnList.showSSL})
    }

    return (
        <ModalStyled
            mask={false}
            visible={props.visible}
            title="Columns"
            onCancel={props.closeColumnsSettingsModal}
            footer={[
                <Button key="back" onClick={props.closeColumnsSettingsModal}>
                    Close
                </Button>             
            ]}
        >
            <FormStyled>
                <FormItemStyled 
                    label="Компания" 
                    style={{display: props.clientType === 'uspd' ? 'none' : ''}}
                >
                    <Switch
                        checked={columnList.showCompany}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showCompanyChange}
                    />
                </FormItemStyled>
                <FormItemStyled label={props.clientType === 'uspd' ? "Компания" : 'Наименование'}>
                    <Switch
                        checked={columnList.showName}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showNameChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Info">
                    <Switch
                        checked={columnList.showInfo}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showInfoChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="УСПД">
                    <Switch
                        checked={columnList.showUSPD}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showUSPDChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Сервис">
                    <Switch
                        checked={columnList.showServiceStatus}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showServiceStatusChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Город">
                    <Switch
                        checked={columnList.showCity}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showCityChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="IP">
                    <Switch
                        checked={columnList.showIP}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showIPChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="VPN">
                    <Switch
                        checked={columnList.showVPN}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showVPNChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="ОС">
                    <Switch
                        checked={columnList.showOS}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showOSChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Мониторинг">
                    <Switch
                        checked={columnList.showMonitoringStatus}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showMonitoringStatusChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Статус">
                    <Switch
                        checked={columnList.showStatus}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showStatusChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Рассылка">
                    <Switch
                        checked={columnList.showMonitoringParamsStatus}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showMonitoringParamsStatusChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="РуТокен">
                    <Switch
                        checked={columnList.showSSL}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showSSLChange}
                    />
                </FormItemStyled>
            </FormStyled>
        </ModalStyled>
    )
}


const ModalStyled = styled(Modal)`
    @media (min-width: 1920px) {
        top: 1px;
        right: 0;
        width: 100% !important;
    }

    @media (max-width: 1919px) {
        max-width: 250px !important;
        top: 250px;
    }

    @media (max-width: 575px) {
        max-width: 100px !important;
        top: 250px;
    }
    
    /* убираем хеадер */
    div.ant-modal-header {
        display: none;
    }
    span.ant-modal-close-x {
        display: none;
    }
`
const FormStyled = styled(Form)`
    /* стилизация блока с кнопками */
    @media (min-width: 1920px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`

const FormItemStyled = styled(Form.Item)`
    margin: 5px 0;
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 55px;
    }
`

export default ColumnsListSettingsModal
