import React from 'react'
import { IClient } from '@interfaces/IClients'

interface IAIProps {
    client: IClient
    clientType: string
}

const AdditionalInfo: React.FC<IAIProps> = (props) => {
    let keyarr: string[]

    if(props.clientType === 'uspd') {
        keyarr = ['newuspd', 'uspduser', 'xmlutm', 'monitor']
    } else {
        keyarr = ['xmlutm', 'newmobileuspd', 'usermobile']
    }

    return (
        <div>
            {Object.keys(props.client).map((value) =>
                keyarr.includes(value) ? (
                    <h3
                        key={value}
                        style={
                            props.client[(value + '_status') as keyof IClient] ? { color: 'green' } : { color: 'red' }
                        }
                    >
                        {value} : {props.client[value as keyof IClient]}
                    </h3>
                ) : undefined,
            )}
        </div>
    )
}

export default AdditionalInfo
