import $api from '@http/$api'
import { IClientUspd, IClientTank, IClientContainer } from '@interfaces/IClients'

const getUspd = async () => {
    // console.log('ClinetAPI GET_USPD')
    const response = await $api.get<IClientUspd[]>('/list/uspd')
    return response.data
}

const getTank = async () => {
    // console.log('ClinetAPI: GET_TANK')
    const response = await $api.get<IClientTank[]>('/list/tank')
    return response.data
}

const getContainer = async () => {
    // console.log('ClinetAPI GET_CONTAINER')
    const response = await $api.get<IClientContainer[]>('/list/container')
    return response.data
}

const ClientsApi = {
    getUspd,
    getTank,
    getContainer,
}

export default ClientsApi
