import { useState, useEffect, memo, useMemo } from 'react'
// Interfaces
import { IAccessMonitoring, IAccessMonitoringTank } from '@interfaces/IAccessMonitoring'
// custom components
import { Button as CustomButton } from '@components/UI/Button/Button'
// components
import TankUpdate from '@components/AccessMonitoring/TankUpdate'
import TankCreate from '@components/AccessMonitoring/TankCreate'
import UserList from '@components/AccessMonitoring/UserList'
// styled
import styled from 'styled-components'
// antd
import { Button } from 'antd'


const UserListMemo = memo(UserList)


interface IProps {
    record: IAccessMonitoring
    refetch: () => void
}



const Row: React.FC<IProps> = ({record, refetch}) => {

    const [selectTank, setSelectTank] = useState({} as IAccessMonitoringTank)
    const [tankUpdateVisible, setTankUpdateVisible] = useState<boolean>(false)
    const [tankCreateVisible, setTankCreateVisible] = useState<boolean>(false)
    const [userListVisible, setUserListVisible] = useState<boolean>(false)
    
    const closeTankUpdateModal = () => {
        setTankUpdateVisible(false)
    }

    const closeTankCreateModal = () => {
        setTankCreateVisible(false)
    }

    const closeUserListVisible = () => {
        setUserListVisible(false)
    }

    const recordMemo = useMemo(() => {
        return record
    }, [])

    

    return (
        <RowStyled>

            <Head>
                <CompanyName>{record.cname}</CompanyName>
                <Button onClick={() => setUserListVisible(true)}>Список пользователей</Button>
                <Button onClick={() => setTankCreateVisible(true)}>Добавить машину</Button>
            </Head>

            <TankListWrapper>
                {record.data.map(tank => {
                    return (
                        <CustomButton 
                            key={tank.tank_id} 
                            onClick={() => {
                                setSelectTank(tank)
                                setTankUpdateVisible(true)
                            }}
                        >
                            {tank.name}
                        </CustomButton>
                    ) 
                })}
            </TankListWrapper>

            <TankUpdate
                tankData={selectTank}
                visible={tankUpdateVisible}
                refetch={refetch}
                closeTankUpdateModal={closeTankUpdateModal}
            />
            <TankCreate
                carier={record}
                visible={tankCreateVisible}
                refetch={refetch}
                closeTankCreateModal={closeTankCreateModal}
            />
            <UserList
                record={record}
                visible={userListVisible}
                // refetch={refetch}
                closeUserListVisible={closeUserListVisible}
            />


        </RowStyled>
    )
}

export default Row


const RowStyled = styled.div`
    display: flex;
`

const Head = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto 10px auto 0;
    white-space: nowrap;
    min-width: ${window.innerWidth <= 991 ? `200px` : `300px`};
`

const CompanyName = styled.div`
    text-align: center;
`

const TankListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: auto; */
`

const AddTank = styled.div`
    margin: 0;
    padding: 5px;
    border: 1px dashed #ccc;

    :hover {
        background-color: #30396bab;
        color: #fff;
        cursor: pointer;
    }
`