import React from 'react'
import { Layout } from 'antd'
import DevicesList from '@components/DevicesList/DevicesList-Devices'
import '@styles/device.css'

const DevicesListPage: React.FC = () => {
    
    return (
        <Layout>
            <Layout.Content>
                <DevicesList />
            </Layout.Content>
        </Layout>
    )
}

export default DevicesListPage
