import React, { useState, useEffect } from 'react'
import { IDevicesColumnListShow } from '@interfaces/IColumnsListShow'
import { Modal, Form, Switch, Button } from 'antd'
import styled from 'styled-components'

interface IUCProps {
    columnList: IDevicesColumnListShow
    visible: boolean
    onChange: (obj: IDevicesColumnListShow) => void
    closeColumnsSettingsModal: () => void
    showColumnsSettingsModal: () => void
}

const ColumnsListSettingsModal: React.FC<IUCProps> = (props) => {

    // состояние кпопок
    const [columnList, setColumnList] = useState(props.columnList)
    
    // название открытой и закрытой кнопок
    const openLable = 'Open'
    const closeLable = 'Close'

    // если изменяется состояние кнопки, прокидываем это сразу в таблицу
    useEffect(() => {
        props.onChange(columnList)
    }, [columnList])
    
    // для кадого переключателя своя функция изменения общего состояния
    const showTypeDeviceChange = () => {
        setColumnList({...columnList, showTypeDevice: !columnList.showTypeDevice})
    }
    const showCompanyChange = () => {
        setColumnList({...columnList, showCompany: !columnList.showCompany})
    }
    const showUSPDChange = () => {
        setColumnList({...columnList, showUSPD: !columnList.showUSPD})
    }
    const showMarkChange = () => {
        setColumnList({...columnList, showMark: !columnList.showMark})
    }
    const showSerialNumberChange = () => {
        setColumnList({...columnList, showSerialNumber: !columnList.showSerialNumber})
    }
    const showEndDateChange = () => {
        setColumnList({...columnList, showEndDate: !columnList.showEndDate})
    }

    return (
        <ModalStyled
            mask={false}
            visible={props.visible}
            title="Columns"
            onCancel={props.closeColumnsSettingsModal}
            footer={[
                <Button key="back" onClick={props.closeColumnsSettingsModal}>
                    Close
                </Button>             
            ]}
        >
            <FormStyled>
                <FormItemStyled label="Тип устройства">
                    <Switch
                        checked={columnList.showTypeDevice}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showTypeDeviceChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Компания">
                    <Switch
                        checked={columnList.showCompany}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showCompanyChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="УСПД">
                    <Switch
                        checked={columnList.showUSPD}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showUSPDChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Серийный номер">
                    <Switch
                        checked={columnList.showSerialNumber}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showSerialNumberChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Модель">
                    <Switch
                        checked={columnList.showMark}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showMarkChange}
                    />
                </FormItemStyled>
                <FormItemStyled label="Дата окончания поверки">
                    <Switch
                        checked={columnList.showEndDate}
                        checkedChildren={openLable}
                        unCheckedChildren={closeLable}
                        onChange={showEndDateChange}
                    />
                </FormItemStyled>   
            </FormStyled>
        </ModalStyled>
    )
}


const ModalStyled = styled(Modal)`
    @media (min-width: 1920px) {
        top: 1px;
        right: 0;
        width: 55% !important;
    }

    @media (max-width: 1919px) {
        max-width: 250px !important;
        top: 250px;
    }

    @media (max-width: 575px) {
        max-width: 160px !important;
        top: 250px;
    }
    
    /* убираем хеадер */
    div.ant-modal-header {
        display: none;
    }
    span.ant-modal-close-x {
        display: none;
    }
`
const FormStyled = styled(Form)`
    /* стилизация блока с кнопками */
    @media (min-width: 1920px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`

const FormItemStyled = styled(Form.Item)`
    margin: 5px 0;
    display: flex;
    justify-content: space-between;

    // редактируем поле input
    > .ant-form-item-control {
        max-width: 55px;
    }
`

export default ColumnsListSettingsModal
