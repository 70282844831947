// '03.09.1979' -> 3532 || NaN
export const dateToDays = (date: string) => {
    if(date && date.length > 0 && (!date.includes('0000') || !date.includes('00'))) {
        const dateArr = date.split(".")   // ['03', '09', '1979']
        const defaultFormat = new Date(+dateArr[2], (+dateArr[1] - 1), +dateArr[0]); // Mon Sep 03 1979 00:00:00 GMT+0300 (Москва, стандартное время)
        const miliseconds = +defaultFormat // 305154000000
        const days = miliseconds / 1000 / 60 / 60 / 24 // 3531.875
        return Math.ceil(days) // 3532
    } 
    return NaN // incorrect Date (00.00.0000 or undefined)
}

// '03.07.2021' -> '2021-07-03'
export const customDateFormatToISO = (date: string) => {
    const dateArr = date.split(".")   // ['03', '09', '1979']
    return dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0] // '2021-11-12'
}
