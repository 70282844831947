import { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { privateRoutes, publicRoutes, RoutePaths } from '@routes/Routes'
import { UserContext } from '@context/UserContext'


const Private = () => {

    return (
        <Routes>
            {privateRoutes.map((route) => (
                <Route path={route.path} element={<route.component />} key={route.path} />
            ))}
            <Route 
                path="*" 
                element={<Navigate replace to={`/${localStorage.getItem('globalPage')}`} />} 
            />
        </Routes>
    )
}

const Public = () => {
    return (
        <Routes>
            {publicRoutes.map((route) => (
                <Route path={route.path} element={<route.component />} key={route.path} />
            ))}
            <Route path="*" element={<Navigate replace to={RoutePaths.LOGIN} />} />
        </Routes>
    )
}

const AppRouter = () => {
    const auth = useContext(UserContext)

    return auth.isAuthenticated ? <Private /> : <Public />
}

export default AppRouter
