import React, { useState, useEffect } from 'react'
// UI
import { CustomModal } from '@components/UI/CustomModal/CustomModal'
import { CustomInput } from '@components/UI/Input/CustomInput'
// ANTD
import { Button, Modal, Input, Form, message, Select, Popconfirm, DatePicker, Space, Switch } from 'antd'
import { SyncOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
// interfaces
import { IAccessMonitoring, IAccessMonitoringNewUser } from '@interfaces/IAccessMonitoring'
// Axios
import $api from '@http/$api'
// validate
import { standardDateValidate, nameValidate, passValidate } from "@utils/validation"

interface IProps {
    carrier: IAccessMonitoring
    visible: boolean
    refetch: () => void
    closeUserCreateModal: () => void
}

interface IFildsValid {
    login: boolean
    pay_date: boolean
    password_cipher: boolean
}

const UserCreate = (props: IProps) => {


    const [newUser, setNewUser] = useState( {} as IAccessMonitoringNewUser)
    const [loading, setLoading] = useState<boolean>(false)
    const [isValidForm, setIsValidForm] = useState<IFildsValid>({
        login: false, pay_date: false, password_cipher: false
    })

    // инструмент для использования API Form
    const [form] = Form.useForm();

    // сохраняем данные юзера в локальный стэйт
    const changeNewUserData = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({...newUser, [event.target.name]: event.target.value})
    }

    // если изменилась валидность какого-то поля, фиксируем это в стэйте
    const changeValidFormState = (name: string, isValid: boolean ) => {
        setIsValidForm({...isValidForm, [name]: isValid})
    }

    const createUserFetch = async () => {
        // отправка началась
        setLoading(true)

        if(Object.values(isValidForm).every(fieldValid => fieldValid === true)) {
            await $api.post('/monitoring/addUser', {
                login: newUser.login,
                password_cipher: newUser.password_cipher,
                carrier_id: props.carrier.carrier_id,
                pay_date: newUser.pay_date,
            }).catch((error) => {
                // отправка звершилась не удачно
                setLoading(false)
                message.error('Не удалось создать!')
                message.error(error.response.data.err)
                throw new Error(error.response.data.err)
            })
        } else {
            setLoading(false)
            message.error('Неверно заполнены поля формы')
            return
        }
       
        // останавливаем процесс загрузки
        setLoading(false)

        // выводим сообщение об успешном обновлении
        message.success('Создание прошло успешно!')

        // после обновления данных в базе, перезапрашиваем весь список клиентов
        props.refetch()

        // после отправки очищаем поля
        clearFields()

        // закрываем сокно создания пользователя
        props.closeUserCreateModal()
    }

    // очистка сохраненных данных и полей ввода
    const clearFields = () => {
        form.resetFields() 
        setNewUser({} as IAccessMonitoringNewUser)
    }
    
    // Если отменяем создание выводим об этом оповещение
    // закрываем окно и очищаем поля ввода
    const onCancelCreate = () => {
        message.info('Создание отменено!') 
        props.closeUserCreateModal()
        clearFields()
    }

    return (
        <CustomModal
            mask={false}
            title={`Добавление нового пользователя для: ${props.carrier.cname}`}
            onCancel={onCancelCreate}
            visible={props.visible}
            footer={
                <Popconfirm
                    title="Создать?"
                    onConfirm={createUserFetch}
                    onCancel={() => {message.info('Создание отменено!')}}
                    okText="Да"
                    cancelText="Нет"
                    icon={<SyncOutlined style={{ color: '#1890ff' }} />}
                >
                    <Button
                        type="primary" 
                        ghost
                        htmlType="submit"
                        className="update-btn footer-btn"
                        // onClick={createUserFetch}
                        loading={loading} 
                    >
                        Создать
                    </Button>
                </Popconfirm>
            }
        >
            <Form form={form}>

                {/* login */}
                <Form.Item 
                    label="Логин" 
                    name="login" 
                    rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}
                >
                    <CustomInput
                        autoComplete="chrome-off"
                        type="text"
                        name="login"
                        label="Логин"
                        onChange={changeNewUserData}
                        value={newUser.login}
                        validator={nameValidate}
                        isValid={changeValidFormState}
                        info={{text: "Только русские и английские символы и цифры длинной от 2 до 20 символов"}}
                        maxLength={20}
                    />
                </Form.Item >

                {/* password_cipher */}
                <Form.Item 
                    label="Пароль" 
                    name="password_cipher" 
                    rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}
                >
                    <CustomInput
                        autoComplete="new-password"
                        type="password"
                        name="password_cipher"
                        label="Пароль"
                        onChange={changeNewUserData}
                        value={newUser.password_cipher}
                        info={{text: "Только английские символы и цифры длинной от 4 до 20 символов"}}
                        validator={passValidate}
                        isValid={changeValidFormState}
                        maxLength={20}
                    />
                </Form.Item >

                 {/* pay_date */}
                 <Form.Item 
                    label="Дата оплаты" 
                    name="pay_date" 
                    rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}
                >
                    <CustomInput
                        autoComplete="chrome-off"
                        type="text"
                        name="pay_date"
                        label="Дата оплаты"
                        onChange={changeNewUserData}
                        value={newUser.pay_date}
                        validator={standardDateValidate}
                        info={{text:'ГГГГ-ММ-ДД'}}
                        isValid={changeValidFormState}
                        maxLength={10}
                    />
                </Form.Item >

            </Form>
        </CustomModal>
    )
}

export default UserCreate
